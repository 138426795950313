.main-wrapper {
  .blog-listing-section {
    padding: 55px 0;
    &.blog-details {
      padding: 55px 0 0;
      .blog-listing-box {
        background-color: #fff;
        .blog-listing-text {
          padding: 30px 0 50px;
          h1 {
            font-size: 34px;
            line-height: 43px;
            margin-bottom: 26px;
          }
          p {
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 40px;
          }
          a.btn-primary {
            width: 12%;
            margin: 0 auto;
          }
        }
      }
      .blog-listing-title {
        h1 {
          margin-bottom: 50px;
        }
      }
    }
    .blog-details-section {
      background-color: #f3f4f4;
      padding: 60px 0;
      .blog-listing-box {
        .blog-listing-text {
          padding: 26px 20px;
          h1 {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 13px;
          }
          p {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 15px;
          }
          a.btn-primary {
            width: 100%;
          }
        }
      }
    }
    .blog-listing-title {
      h1 {
        font-size: 34px;
        line-height: 43px;
        color: #152c40;
        margin-bottom: 40px;
        font-family: "DIN_2014";
        font-weight: 700;
      }
    }
    a {
      text-decoration: none;
      .blog-listing-box {
        background-color: #f3f4f4;
        margin-bottom: 25px;
        cursor: pointer;
        .blog-listing-img {
          img {
            width: 100%;
            height: 250px;
            object-fit: cover;
          }
        }
        .blog-listing-text {
          padding: 26px 20px;
          h1 {
            color: #313a41;
            font-size: 16px;
            line-height: 20px;
            font-family: "DIN_2014";
            margin-bottom: 13px;
            font-weight: 600;
          }
          p {
            color: #152c40;
            font-size: 14px;
            line-height: 21px;
            font-family: "DIN_2014";
            font-weight: 300;
            margin-bottom: 10px;
          }
          .meta-middle-unit {
            display: flex;
            margin-bottom: 10px;
            .blog-listing-date {
              display: flex;
              margin-right: 15px;
              align-items: center;
              &:last-child {
                margin-right: 0;
              }
              img {
                width: 18px;
                height: 16px;
                margin-right: 8px;
                object-fit: contain;
              }
              span {
                font-size: 14px;
                color: #77868d;
                font-family: "DIN_2014";
                font-weight: 500;
                line-height: 44px;
              }
            }
          }
          a.btn-primary {
            position: relative;
            display: block;
            overflow: hidden;
            width: 100%;
            border: 2px solid #152c40;
            box-shadow: 0px 3px 6px #0000001a;
            background-color: #ffffff;
            color: #152c40;
            border-radius: 5px;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            font-weight: 600;
            font-family: "DIN_2014";
            transition: 0.9s ease-in-out;
            padding: 13px 0;
            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: -50px;
              bottom: 0;
              left: 0;
              border-right: 50px solid transparent;
              border-bottom: 50px solid #152c40;
              transform: translateX(-100%);
              transition: 0.6s ease-in-out;
              font-family: "DIN 2014 Bold";
            }

            &:hover {
              &::before {
                transform: translateX(0);
                z-index: -1;
                font-family: "DIN 2014 Bold";
              }

              color: #e7f0f4;
              z-index: 9;
            }
          }
        }
      }
    }
    button {
      &.primary-btn-list {
        position: relative;
        display: block;
        overflow: hidden;
        width: 13%;
        height: 50px;
        border: 2px solid #152c40;
        box-shadow: 0px 3px 6px #0000001a;
        background-color: #ffffff;
        color: #152c40;
        border-radius: 5px;
        font-size: 15px;
        line-height: 23px;
        text-align: center;
        font-weight: 600;
        font-family: "DIN_2014";
        transition: 0.9s ease-in-out;
        margin: 20px auto 0;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: -50px;
          bottom: 0;
          left: 0;
          border-right: 50px solid transparent;
          border-bottom: 50px solid #152c40;
          transform: translateX(-100%);
          transition: 0.6s ease-in-out;
        }

        &:hover {
          &::before {
            transform: translateX(0);
            z-index: -1;
          }

          color: #e7f0f4;
          z-index: 9;
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .main-wrapper {
    .blog-listing-section {
      button {
        &.primary-btn-list {
          width: 40%;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .main-wrapper {
    .blog-listing-section {
      button {
        &.primary-btn-list {
          width: 30%;
        }
      }
      &.blog-details {
        padding: 0px 0;
        a {
          .blog-listing-box {
            margin-bottom: 0;
            .blog-listing-img {
              margin: 0 -12px;
              img {
                height: 100%;
                object-fit: contain;
              }
            }
            .blog-listing-text {
              padding: 30px 0;
              h1 {
                font-size: 24px;
                line-height: 31px;
                margin-bottom: 20px;
              }
              p {
                font-size: 12px;
                line-height: 21px;
                margin-bottom: 26px;
              }
              a {
                &.btn-primary {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .main-wrapper {
    .blog-listing-section {
      padding: 27px 0 34px;
      .blog-listing-title {
        h1 {
          font-size: 24px;
          line-height: 31px;
          margin-bottom: 20px;
        }
      }
      a {
        .blog-listing-box {
          margin-bottom: 10px;
          .blog-listing-text {
            padding: 11px 11px 19px;
            h1 {
              font-size: 15px;
            }
            .meta-middle-unit {
              .blog-listing-date {
                img {
                  margin-right: 7px;
                }
                span {
                  font-size: 12px;
                  line-height: 22px;
                }
              }
            }
          }
        }
      }
      button {
        &.primary-btn-list {
          width: 100%;
          font-size: 18px;
        }
      }
      &.blog-details {
        padding: 0px 0;
        a {
          .blog-listing-box {
            margin-bottom: 0;
            .blog-listing-img {
              margin: 0 -12px;
              img {
                height: 100%;
                object-fit: contain;
              }
            }
            .blog-listing-text {
              padding: 30px 0;
              h1 {
                font-size: 24px;
                line-height: 31px;
                margin-bottom: 20px;
              }
              p {
                font-size: 12px;
                line-height: 21px;
                margin-bottom: 26px;
              }
              a {
                &.btn-primary {
                  width: 100%;
                }
              }
            }
          }
        }
        .blog-details-section {
          padding: 34px 0 45px;
          .blog-listing-title {
            h1 {
              margin-bottom: 25px;
            }
          }
          .blog-listing-box {
            margin-bottom: 10px;
            .blog-listing-img {
              margin: 0;
              img {
                height: 200px;
                object-fit: cover;
              }
            }
          }
          .blog-listing-text {
            padding: 11px 11px 19px !important;
            h1 {
              font-size: 15px !important;
              line-height: 18px !important;
              margin-bottom: 13px !important;
            }
            p {
              font-size: 12px !important;
              line-height: 18px !important;
              margin-bottom: 13px !important;
            }
          }
        }
      }
    }
  }
}
