.main-wrapper {
  .contact-us-section {
    .blue-bg {
      display: flex;
      flex-direction: row-column;
      .contact-us-left {
        width: 50%;
        height: 100%;
        background-color: #e7f0f4;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1019607843);
        padding: 75px 91px 100px 112px;
        height: 730px;
        h2 {
          font-size: 64px;
          font-weight: 800;
          line-height: 82px;
          color: #152c40;
          margin-bottom: 30px;
          font-family: "DIN_2014";
        }
        .form-container {
          .form-group {
            margin-bottom: 22px;
            .form-control {
              border-radius: 5px;
              border: 1px solid #77868d;
              box-shadow: 0px 0px 3px #00000029;
              height: 50px;
              padding: 0 20px;
              font-size: 14px;
              line-height: 25px;
              color: #313a41;
              font-weight: 500;
              font-family: "DIN_2014";
              background-color: #fff;
              &::placeholder {
                font-size: 14px;
                line-height: 25px;
                color: #313a41;
                font-weight: 500;
                font-family: "DIN_2014";
              }
            }
            &.textarea {
              padding-top: 12px;
              margin-bottom: 50px;
              textarea {
                border-radius: 5px;
                border: 1px solid #77868d;
                box-shadow: 0px 0px 3px #00000029;
                height: 100px;
                padding: 6px 20px;
                font-size: 14px;
                line-height: 25px;
                color: #313a41;
                font-weight: 500;
                font-family: "DIN_2014";
                background-color: #fff;
                outline: none;
                width: 100%;
                &::placeholder {
                  font-size: 14px;
                  line-height: 25px;
                  color: #313a41;
                  font-weight: 500;
                  font-family: "DIN_2014";
                }
              }
            }
          }
          button {
            &.primary-btn {
              position: relative;
              display: block;
              overflow: hidden;
              width: 34%;
              height: 60px;
              text-transform: uppercase;
              box-shadow: 0px 3px 6px #0000001a;
              background-image: linear-gradient(107deg, #202A50 0%, #0E77BD 50%, #073C5F 100%);
              color: #e7f0f4;
              border-radius: 5px;
              font-size: 16px;
              line-height: 60px;
              text-align: center;
              font-weight: 600;
              font-family: "DIN_2014";
              transition: 0.9s ease-in-out;
              border: 0px solid; 
              border-image-slice: 1;
              border-image-source: linear-gradient(107deg, #202A50 0%, #0E77BD 50%, #073C5F 100%);

              &::before {
                content: "";
                position: absolute;
                top: 0;
                right: -50px;
                bottom: 0;
                left: 0;
                border-right: 50px solid transparent;
                border-bottom: 60px solid #e7f0f4;
                transform: translateX(-100%);
                transition: 0.6s ease-in-out;
              }

              &:hover {
                &::before {
                  transform: translateX(0);
                  z-index: -1;
                }

                color: #152c40;
                z-index: 9;

                .arrow-icon {
                  background-color: #152c40;
                }
              }
            }

            .arrow-icon {
              position: absolute;
              right: 15px;
              top: 25px;
              transition: 0.9s ease-in-out;
              background-color: #e7f0f4;
              background-repeat: no-repeat;
              display: inline-block;
              height: 11px;
              -webkit-mask-image: url(../images/arrow_icon.svg);
              mask-image: url(../images/arrow_icon.svg);
              width: 14px;
            }
          }
        }
      }
      .col-image-right {
        width: 50%;
        height: 730px;
        img {
          width: 100%;
          height: 730px;
        }
      }
    }
    .contact-us-right {
      padding: 60px 0;
      .contact-us-right-content {
        ul {
          padding: 0px;
          margin: 0 auto;
          list-style: none;
          display: flex;
          justify-content: center;
          background: #e7f0f4;
          border-radius: 5px;
          width: 450px;
          align-items: center;

          li {
            // margin-right: 23px;
            padding: 14px 16px;
            cursor: pointer;
            width: 178px;
            text-align: center;
            &.active {
              color: #313a41;
              background-image: linear-gradient(107deg, #202A50 0%, #0E77BD 50%, #073C5F 100%);
              border-radius: 5px;
              padding: 14px 17px;
              width: 178px;
              a {
                color: #e7f0f4;
                &:hover {
                  color: #e7f0f4;
                }
              }
            }
            &:last-child {
              margin-right: 0;
            }
            a {
              color: #77868d;
              text-decoration: none;
              font-size: 14px;
              font-family: "DIN_2014";
              font-weight: 600;
              letter-spacing: 1px;
              &:hover {
                color: #313a41;
              }
            }
          }
        }
        .contact-details {
          overflow: hidden;
          padding: 50px 57px 30px 57px;
          .contact-item {
            display: flex;
            .map-img {
              width: 100%;
              iframe {
                width: 100%;
              }
            }
            .contact-info {
              margin-left: 53px;
              width: 350px;
              h2 {
                font-size: 36px;
                font-weight: 600;
                line-height: 50px;
                color: #152c40;
                margin-bottom: 30px;
                font-family: "DIN_2014";
              }
              ul {
                padding: 0px;
                margin: 0px 0 0 27px;
                list-style: none;
                display: flex;width: 187px;
                flex-direction: column;
                background-color: transparent;
                align-items: flex-start;
                li {
                  display: flex;
                  margin-bottom: 20px;
                  flex-direction: column;
                  align-items: flex-start;
                  padding: 0;
                  &:nth-child(1) {
                    a {
                      .contact-address {
                        display: flex;
                        flex-direction: column;
                        span {
                          margin-top: 2px;
                        }
                      }
                    }
                  }
                  a {
                    color: #152c40;
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    position: relative;
                    font-family: "DIN_2014";
                    letter-spacing: 0;
                    img {
                      margin-right: 9px;
                      width: 15px;
                      position: absolute;
                      left: -25px;
                      top: 1px;
                    }
                    span {
                      font-size: 14px;
                      font-weight: 300;
                      line-height: 23px;
                      color: #77868d;
                      text-align: left;
                      font-family: "DIN_2014";
                      letter-spacing: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .main-wrapper {
    .contact-us-section {
      .blue-bg {
        flex-direction: column-reverse;
        .contact-us-left {
          width: 100%;
          padding: 25px 0px;
          height: auto;
          h2 {
            text-align: center;
            font-size: 24px;
            line-height: 31px;
            margin-bottom: 22px;
          }
          .form-container {
            .form-group {
              margin-bottom: 14px;
              &.textarea {
                padding-top: 0;
                margin-bottom: 22px;
              }
            }
            button {
              &.primary-btn {
                width: 100%;
                height: 50px;
                line-height: 50px;
                &::before {
                  border-bottom: 50px solid #e7f0f4;
                }
              }
              .arrow-icon {
                top: 19px;
              }
            }
          }
        }
        .col-image-right {
          width: 100%;
          height: auto;
          img {
            height: 260px;
            object-fit: cover;
          }
        }
      }
      .contact-us-right {
        padding: 30px 0;
        .contact-us-right-content {
          ul {
            width: 100%;
            li {
              padding: 14px 10px;
              line-height: 20px;
              &.active {
                padding: 14px 5px;
              }
              a {
                font-size: 11px;
              }
            }
          }
          .contact-details {
            padding: 32px 0 0;
            .contact-item {
              flex-direction: column-reverse;
              .contact-info {
                margin-left: 0;
                width: 100%;
                text-align: center;
                h2 {
                  font-size: 24px;
                  line-height: 31px;
                  margin-bottom: 20px;
                  font-family: "DIN 2014 Bold";
                }
                ul {
                  margin-left: 0;
                  align-items: center;
                  width: 100%;
                  li {
                    align-items: center;
                    width: 100%;
                    &:nth-child(1) {
                      a {
                        .contact-address {
                          display: block;
                          span {
                            margin-top: 0px;
                            margin-right: 2px;
                          }
                        }
                      }
                    }
                    a {
                      text-align: center;
                      align-items: center;
                      img {
                        position: relative;
                        left: 0;
                        top: 0;
                        margin-right: 0;
                        margin-bottom: 6px;
                      }
                      span {
                        text-align: center;
                        font-family: "DIN2014-Regular";
                        margin-top: 0 !important;
                      }
                      p {
                        font-family: "DIN2014-Regular";
                      }
                    }
                  }
                }
              }
              .map-img {
                iframe {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
