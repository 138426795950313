.main-wrapper {
  .insurance-section {
    padding: 55px 0 0;

    .finance-img {
      img {
        width: 100%;
      }

      .finance-images {
        .finance-title {
          padding-top: 40px;

          h1 {
            font-size: 34px;
            font-family: "DIN_2014";
            line-height: 43px;
            margin-bottom: 35px;
            text-align: center;
            font-weight: 600;
          }
        }
      }
    }

    .insurance-content {
      padding-bottom: 50px;

      p {
        strong {
          font-size: 21px;
          line-height: 27px;
          font-family: "DIN_2014";
          margin-bottom: 25px;
          color: #152c40;
          font-weight: 600;
        }
        span {
          color: #77868d;
          font-size: 15px;
          line-height: 24px;
          margin-bottom: 25px;
          font-family: "DIN_2014";
          font-weight: 300;
        }
      }

      .insurance-text {

        ul {
          padding: 0;
          list-style: none;
          margin-bottom: 25px;

          li {
            color: #77868d;
            font-size: 16px;
            line-height: 20px;
            font-family: "DIN2014-Regular";
            margin-bottom: 7px;

            img {
              width: 2%;
              margin-right: 15px;
            }
          }
        }
      }
    }

    .european-insurance-form {
      width: 100%;
      height: 100%;
      background-color: #f3f4f4;
      padding: 77px 0;

      h2 {
        font-size: 34px;
        line-height: 43px;
        color: #152c40;
        margin-bottom: 45px;
        font-family: "DIN_2014";
        text-align: center;
        font-weight: 600;
      }

      .insurance-group {
        width: 42%;
        margin: 0 auto;

        .form-group {
          margin-bottom: 22px;

          .form-control {
            border-radius: 5px;
            border: 1px solid #77868d;
            box-shadow: 0px 0px 3px #00000029;
            height: 50px;
            padding: 0 20px;
            font-size: 14px;
            line-height: 25px;
            color: #77868d;
            font-weight: 400;
            font-family: "DIN_2014";
            background-color: #fff;

            &::placeholder {
              font-size: 14px;
              line-height: 25px;
              color: #77868d;
              font-weight: 400;
              font-family: "DIN_2014";
            }
          }

          &.textarea {
            padding-top: 12px;
            margin-bottom: 50px;

            textarea {
              border-radius: 5px;
              border: 1px solid #77868d;
              box-shadow: 0px 0px 3px #00000029;
              height: 100px;
              padding: 6px 20px;
              font-size: 14px;
              line-height: 25px;
              color: #77868d;
              font-weight: 400;
              font-family: "DIN_2014";
              background-color: #fff;
              outline: none;
              width: 100%;

              &::placeholder {
                font-size: 14px;
                line-height: 25px;
                color: #77868d;
                font-weight: 400;
                font-family: "DIN_2014";
              }
            }
          }
        }

        button {
          &.primary-btn {
            position: relative;
            display: block;
            overflow: hidden;
            width: 100%;
            height: 60px;
            text-transform: uppercase;
            border: 0px solid #152c40;
            box-shadow: 0px 3px 6px #0000001a;
            border: 0px solid;
            border-image-slice: 1;
            border-image-source: linear-gradient(107deg, #202A50 0%, #0E77BD 50%, #073C5F 100%);
            background-image: linear-gradient(107deg, #202A50 0%, #0E77BD 50%, #073C5F 100%);
            color: #e7f0f4;
            border-radius: 5px;
            font-size: 16px;
            line-height: 60px;
            text-align: center;
            font-weight: 600;
            font-family: "DIN_2014";
            transition: 0.9s ease-in-out;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: -50px;
              bottom: 0;
              left: 0;
              border-right: 50px solid transparent;
              border-bottom: 60px solid #e7f0f4;
              transform: translateX(-100%);
              transition: 0.6s ease-in-out;
            }

            &:hover {
              &::before {
                transform: translateX(0);
                z-index: -1;
              }

              color: #152c40;
              z-index: 9;
              border-width: 2px;
            }
          }
        }
      }
    }
  }

  &.order-spanish {
    padding: 55px 0 0px;

    .order-title {
      padding-bottom: 40px;

      h1 {
        text-align: center;
        font-family: "DIN_2014";
        font-size: 34px;
        line-height: 43px;
        margin-bottom: 60px;
        font-weight: 600;
      }

      .order-spanish-img {
        img {
          width: 100%;
        }
      }

      .order-spanish-text {
        padding-left: 30px;

        p {
          font-size: 16px;
          line-height: 30px;
          font-family: "DIN_2014";
          color: #152C40;
          margin-bottom: 20px;
          font-weight: 400;
        }
      }
    }

    .european-insurance-form {
      width: 100%;
      height: 100%;
      background-color: #f3f4f4;
      padding: 77px 0;

      h2 {
        font-size: 34px;
        line-height: 43px;
        color: #152c40;
        margin-bottom: 45px;
        font-family: "DIN_2014";
        text-align: center;
        font-weight: 600;
      }

      form {
        width: 49%;
        margin: 0 auto;

        .form-group {
          margin-bottom: 22px;

          .form-control {
            border-radius: 5px;
            border: 1px solid #77868d;
            box-shadow: 0px 0px 3px #00000029;
            height: 50px;
            padding: 0 20px;
            font-size: 14px;
            line-height: 25px;
            color: #77868d;
            font-weight: 400;
            font-family: "DIN_2014";
            background-color: #fff;

            &::placeholder {
              font-size: 14px;
              line-height: 25px;
              color: #77868d;
              font-weight: 400;
              font-family: "DIN_2014";
            }
          }

          &.textarea {
            padding-top: 12px;
            margin-bottom: 50px;

            textarea {
              border-radius: 5px;
              border: 1px solid #77868d;
              box-shadow: 0px 0px 3px #00000029;
              height: 100px;
              padding: 6px 20px;
              font-size: 14px;
              line-height: 25px;
              color: #77868d;
              font-weight: 400;
              font-family: "DIN_2014";
              background-color: #fff;
              outline: none;
              width: 100%;

              &::placeholder {
                font-size: 14px;
                line-height: 25px;
                color: #77868d;
                font-weight: 400;
                font-family: "DIN_2014";
              }
            }
          }
        }

        button {
          &.primary-btn {
            position: relative;
            display: block;
            overflow: hidden;
            width: 100%;
            height: 60px;
            text-transform: uppercase;
            border: 0px solid #152c40;
            box-shadow: 0px 3px 6px #0000001a;
            background-image: linear-gradient(107deg,
                  #202a50 0%,
                  #0e77bd 50%,
                  #073c5f 100%);
            color: #e7f0f4;
            border-radius: 5px;
            font-size: 16px;
            line-height: 60px;
            text-align: center;
            font-weight: 600;
            font-family: "DIN_2014";
            transition: 0.9s ease-in-out;
            border: 0px solid;
            border-image-slice: 1;
            border-image-source: linear-gradient(107deg,
                #202a50 0%,
                #0e77bd 50%,
                #073c5f 100%);

            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: -50px;
              bottom: 0;
              left: 0;
              border-right: 50px solid transparent;
              border-bottom: 60px solid #e7f0f4;
              transform: translateX(-100%);
              transition: 0.9s ease-in-out;
            }

            &:hover {
              &::before {
                transform: translateX(0);
                z-index: -1;
              }

              color: #152c40;
              z-index: 9;
              border-width: 2px;
            }
          }
        }
      }
    }
  }

  &.order-french {
    padding: 55px 0 0px;

    .order-title {
      h1 {
        text-align: center;
        font-family: "DIN_2014";
        font-weight: 600;
        font-size: 34px;
        line-height: 43px;
        margin-bottom: 30px;
      }

      .order-french-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;

        .order-spanish-img {
          margin-right: 0px;

          img {
            width: 100%;
          }
        }

        .order-french-text {
          margin-left: 60px;

          p {
            font-size: 18px;
            line-height: 30px;
            font-weight: 400;
            font-family: "DIN_2014";
            color: #152C40;
          }
        }
      }
    }

    .european-insurance-form {
      width: 100%;
      height: 100%;
      background-color: #f3f4f4;
      padding: 77px 0;

      h2 {
        text-align: center;
        font-family: "DIN_2014";
        font-weight: 600;
        font-size: 34px;
        line-height: 43px;
        margin-bottom: 30px;
      }

      form {
        width: 49%;
        margin: 0 auto;

        .form-group {
          margin-bottom: 22px;

          .form-control {
            border-radius: 5px;
            border: 1px solid #77868d;
            box-shadow: 0px 0px 3px #00000029;
            height: 50px;
            padding: 0 20px;
            font-size: 14px;
            line-height: 25px;
            color: #77868d;
            font-weight: 400;
            background-color: #fff;
            font-family: "DIN_2014";

            &::placeholder {
              font-size: 14px;
              line-height: 25px;
              color: #77868d;
            }
          }

          &.textarea {
            padding-top: 12px;
            margin-bottom: 50px;

            textarea {
              border-radius: 5px;
              border: 1px solid #77868d;
              box-shadow: 0px 0px 3px #00000029;
              height: 100px;
              padding: 6px 20px;
              font-size: 14px;
              line-height: 25px;
              color: #77868d;
              font-weight: 400;
              font-family: "DIN_2014";
              background-color: #fff;
              outline: none;
              width: 100%;

              &::placeholder {
                font-size: 14px;
                line-height: 25px;
                color: #77868d;
                font-family: "DIN_2014";
                font-weight: 400;
              }
            }
          }
        }

        button {
          &.primary-btn {
            position: relative;
            display: block;
            overflow: hidden;
            width: 100%;
            height: 60px;
            text-transform: uppercase;
            border: 0px solid #152c40;
            box-shadow: 0px 3px 6px #0000001a;
            background-image: linear-gradient(107deg,
                  #202a50 0%,
                  #0e77bd 50%,
                  #073c5f 100%);
            color: #e7f0f4;
            border-radius: 5px;
            font-size: 16px;
            line-height: 60px;
            text-align: center;
            font-weight: 600;
            font-family: "DIN_2014";
            transition: 0.9s ease-in-out;
            border: 0px solid;
            border-image-slice: 1;
            border-image-source: linear-gradient(107deg,
                #202a50 0%,
                #0e77bd 50%,
                #073c5f 100%);

            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: -50px;
              bottom: 0;
              left: 0;
              border-right: 50px solid transparent;
              border-bottom: 60px solid #e7f0f4;
              transform: translateX(-100%);
              transition: 0.9s ease-in-out;
            }

            &:hover {
              &::before {
                transform: translateX(0);
                z-index: -1;
              }

              color: #152c40;
              z-index: 9;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .main-wrapper.order-french .order-title .order-french-row .order-french-text {
    margin-left: 14px;
  }
}

@media screen and (max-width: 992px) {
  .main-wrapper {
    .insurance-section {
      padding: 27px 0 0;

      .title {
        h2 {
          font-size: 24px;
          line-height: 31px;
          margin-bottom: 20px;
          text-align: center;
        }
      }

      .finance-img {
        img {
          height: 220px;
          margin-bottom: 20px;
          object-fit: cover;
        }
      }

      .insurance-content {
        padding-bottom: 5px;

        .insurance-text {
          h3 {
            font-size: 21px;
            line-height: 27px;
            margin-bottom: 15px;
          }

          p {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 21px;
          }

          h5 {
            font-size: 14px;
            line-height: 21px;
          }

          ul {
            margin-bottom: 21px;

            li {
              font-size: 14px;
              padding-left: 33px;


              img {
                width: 21px;
                height: 21px;
                position: absolute;
                left: 0px;
              }
            }
          }
        }
      }

      .european-insurance-form {
        padding: 40px 0;

        h2 {
          font-size: 24px;
          line-height: 31px;
          margin-bottom: 25px;
          text-align: center;
        }

        form {
          width: 100%;

          .form-group {
            margin-bottom: 16px;

            &.textarea {
              padding-top: 0;
              margin-bottom: 25px;
            }
          }
        }
      }
    }

    &.order-spanish {
      padding: 27px 0 0px;

      .order-title {
        padding-bottom: 20px;

        h1 {
          font-size: 30px;
          line-height: 36px;
          margin-bottom: 19px;
        }

        .order-spanish-img {
          img {
            padding-bottom: 29px;
          }
        }

        .order-spanish-text {
          padding-left: 0px;
        }
      }

      .european-insurance-form {
        padding: 40px 0;

        h2 {
          font-size: 24px;
          line-height: 31px;
          margin-bottom: 25px;
          text-align: center;
        }

        form {
          width: 68%;

          .form-group {
            margin-bottom: 16px;

            &.textarea {
              padding-top: 0;
              margin-bottom: 25px;
            }
          }
        }
      }
    }

    &.order-french {
      padding: 27px 0 0;

      .order-title {
        h1 {
          font-size: 24px;
          line-height: 31px;
          margin-bottom: 19px;
        }

        .order-french-row {
          flex-direction: column;
          margin-bottom: 0px;

          .order-spanish-img {
            margin-right: 0px;

            img {
              padding-bottom: 26px;
            }
          }

          .order-french-text {
            margin-bottom: 30px;

            p {
              font-size: 15px;
              line-height: 24px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .european-insurance-form {
        padding: 40px 0;

        form {
          width: 65%;

        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .main-wrapper {
    .insurance-section {
      padding: 27px 0 0;

      .finance-img {
        img {
          height: 220px;
          margin-bottom: 20px;
          object-fit: cover;
        }

        .finance-images {
          display: flex;
          flex-direction: column-reverse;

          .finance-title {
            padding-top: 0px;

            h1 {
              font-size: 24px;
              line-height: 31px;
              margin-bottom: 20px;
              text-align: center;
            }
          }
        }
      }

      .insurance-content {
        padding-bottom: 5px;

        .insurance-text {
          position: relative;

          h3 {
            font-size: 21px;
            line-height: 27px;
            margin-bottom: 15px;
          }

          p {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 21px;
          }

          h5 {
            font-size: 14px;
            line-height: 21px;
          }

          ul {
            margin-bottom: 21px;

            li {
              font-size: 14px;
              padding-left: 33px;


              img {
                width: 21px;
                height: 21px;
                position: absolute;
                left: 0px;
              }
            }
          }
        }
      }

      .european-insurance-form {
        padding: 40px 0;

        h2 {
          font-size: 24px;
          line-height: 31px;
          margin-bottom: 25px;
          text-align: center;
        }

        .insurance-group {
          width: 100%;
        }

        .form-group {
          margin-bottom: 16px;

          &.textarea {
            padding-top: 0;
            margin-bottom: 25px;
          }
        }
      }
    }

    &.order-spanish {
      padding: 27px 0 0px;

      .order-title {
        padding-bottom: 20px;

        h1 {
          font-size: 24px;
          line-height: 31px;
          margin-bottom: 19px;
        }

        .order-spanish-img {
          img {
            padding-bottom: 29px;
          }
        }

        .order-spanish-text {
          padding-left: 0px;

          p {
            font-size: 12px;
            line-height: 21px;
            margin-bottom: 20px;
          }
        }
      }

      .european-insurance-form {
        padding: 40px 0;

        h2 {
          font-size: 24px;
          line-height: 31px;
          margin-bottom: 25px;
          text-align: center;
        }

        form {
          width: 100%;

          .form-group {
            margin-bottom: 16px;

            &.textarea {
              padding-top: 0;
              margin-bottom: 25px;
            }
          }
        }
      }
    }

    &.order-french {
      padding: 27px 0 0;

      .order-title {
        h1 {
          font-size: 24px;
          line-height: 31px;
          margin-bottom: 19px;
        }

        .order-french-row {
          flex-direction: column;
          margin-bottom: 0px;

          .order-spanish-img {
            margin-right: 0px;

            img {
              padding-bottom: 26px;
            }
          }

          .order-french-text {
            margin-bottom: 30px;

            p {
              font-size: 12px;
              line-height: 21px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .european-insurance-form {
        padding: 40px 0;

        h2 {
          font-size: 24px;
          line-height: 31px;
          margin-bottom: 25px;
          text-align: center;
        }

        form {
          width: 100%;

          .form-group {
            margin-bottom: 16px;

            &.textarea {
              padding-top: 0;
              margin-bottom: 25px;
            }
          }
        }
      }
    }
  }
}