.main-wrapper {
  .about-col-section {
    display: flex;
    flex-direction: row-reverse;

    .col-text.blue-bg {
      width: 50%;

      .our-courses-box {
        width: 100%;
        height: 100%;
        background-color: #e7f0f4;
        box-shadow: 0px 0px 6px #0000001a;

        .date-title {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          height: 77%;
          margin: 0 3% 0 16%;

          h1 {
            font-size: 3.7rem;
            font-weight: 600;
            line-height: 1.2;
            color: #152c40;
            margin-bottom: 30px;
            font-family: "DIN_2014";
          }

          p {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.6;
            color: #77868d;
            margin-bottom: 50px;
            font-family: "DIN_2014";
          }

          button {
            &.primary-btn {
              position: relative;
              display: block;
              overflow: hidden;
              width: 44%;
              height: 60px;
              text-transform: uppercase;
              box-shadow: 0px 3px 6px #0000001a;
              background-image: linear-gradient(107deg, #202A50 0%, #0E77BD 50%, #073C5F 100%);
              color: #e7f0f4;
              border-radius: 5px;
              font-size: 16px;
              line-height: 60px;
              text-align: center;
              font-weight: 600;
              font-family: "DIN_2014";
              transition: 0.9s ease-in-out;
              border: 0px solid;
              border-image-slice: 1;
              border-image-source: linear-gradient(107deg, #202A50 0%, #0E77BD 50%, #073C5F 100%);
              border-width: 2px;

              &::before {
                content: "";
                position: absolute;
                top: 0;
                right: -50px;
                bottom: 0;
                left: 0;
                border-right: 50px solid transparent;
                border-bottom: 60px solid #e7f0f4;
                transform: translateX(-100%);
                transition: 0.6s ease-in-out;
              }

              &:hover {
                &::before {
                  transform: translateX(0);
                  z-index: -1;
                }

                color: #152c40;
                z-index: 9;

                .arrow-icon {
                  background-color: #152c40;
                }
              }
            }

            .arrow-icon {
              position: absolute;
              right: 15px;
              top: 25px;
              transition: 0.9s ease-in-out;
              background-color: #e7f0f4;
              background-repeat: no-repeat;
              display: inline-block;
              height: 11px;
              -webkit-mask-image: url(../images/arrow_icon.svg);
              mask-image: url(../images/arrow_icon.svg);
              width: 14px;
            }
          }
        }
      }
    }

    .col-image {
      width: 50%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .about-list-section {
    padding: 80px 0;
    background-color: #f3f4f4;

    .about-item-box {
      display: flex;
      justify-content: space-between;
      padding: 0 8px;
      cursor: pointer;

      .about-list-box {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 50%;
        flex-direction: column;
        background-color: #ffffff;
        height: auto;
        padding: 50px 40px 40px;

        h1 {
          font-size: 30px;
          font-weight: 600;
          line-height: 38px;
          color: #152c40;
          margin-bottom: 30px;
          font-family: "DIN_2014";
        }

        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 30px;
          color: #77868d;
          margin-bottom: 24px;
          font-family: "DIN_2014";
        }

        h4 {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: #152c40;
          margin-bottom: 24px;
          font-family: "DIN_2014";
        }
      }
    }

    .about-list-img {
      width: 50%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .home-choose-us-section {
    &.about-choose {
      padding: 100px 0 0;

      .choose-us-wrapper {
        margin: 0 13%;

        .choose-us-box {
          margin: 0;

          .choose-us-content {
            width: 100%;
            padding: 63px 40px;
            height: 480px;

            img {
              width: 105px;
              height: 105px;
            }

            h2 {
              padding: 35px 0;
              font-size: 21px;
              color: #fff;

              &::after {
                margin: 50px auto 0;
                width: 83px;
              }
            }

            p {
              font-size: 16px;
              line-height: 28px;
            }
          }
        }

        &:nth-child(1) {
          .choose-us-box {
            &:nth-child(1) {
              .choose-us-content {
                background-color: #77868d;

                img {
                  filter: brightness(0) invert(1);
                }
              }
            }

            &:nth-child(2) {
              .choose-us-content {
                h2 {
                  color: #aab9c0;
                }
              }
            }
          }
        }

        &:nth-child(2) {
          .choose-us-box {
            &:nth-child(2) {
              .choose-us-content {
                background-color: #77868d;

                img {
                  filter: brightness(0) invert(1);
                }
              }
            }

            &:nth-child(1) {
              .choose-us-content {
                img {
                  filter: brightness(0) invert(1);
                }
              }
            }
          }
        }
      }
    }
  }

  .about-google-review {
    background-color: #f3f4f4;
    padding: 80px 0;

    .google-review-wrapper {
      padding-top: 37px;
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(3, 1fr);

      .google-review-box {
        background-color: #fff;
        border: 1px solid #aab9c0;
        border-radius: 5px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, .161);
        padding: 29px;

        .google-review-img {
          align-items: center;
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          img {
            &.google-img {
              width: 140px;
            }
          }

          img {
            &.star-icon {
              width: 27px;
            }
          }
        }

        .google-review-content {
          h2 {
            color: #152c40;
            font-family: DIN_2014;
            font-size: 18px;
            font-weight: 600;
            line-height: 21px;
            margin-bottom: 0;
          }

          p {
            color: #152c40;
            font-family: DIN_2014;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 0;
            padding: 20px 0;
          }
        }
      }
    }

    .google-review-wrapper-inner {
      margin: 0 auto 24px;
    }

    button {
      &.primary-btn-list {
        position: relative;
        display: block;
        overflow: hidden;
        width: 18%;
        height: 50px;
        text-transform: uppercase;
        border: 2px solid #152c40;
        box-shadow: 0px 3px 6px #0000001a;
        background-color: #ffffff;
        color: #152c40;
        border-radius: 5px;
        font-size: 15px;
        line-height: 23px;
        text-align: center;
        font-weight: 600;
        font-family: "DIN_2014";
        transition: 0.9s ease-in-out;
        margin: 46px auto 0;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: -50px;
          bottom: 0;
          left: 0;
          border-right: 50px solid transparent;
          border-bottom: 50px solid #152c40;
          transform: translateX(-100%);
          transition: 0.6s ease-in-out;
        }

        &:hover {
          &::before {
            transform: translateX(0);
            z-index: -1;
          }

          color: #e7f0f4;
          z-index: 9;
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .main-wrapper {
    .home-choose-us-section {
      &.about-choose {
        .choose-us-wrapper {
          margin: 0 0%;
        }
      }
    }

    // .about-google-review {
    //   .google-review-wrapper-box {
    //     .google-review-wrapper {
    //       display: grid;
    //       grid-template-columns: repeat(1, 1fr);
    //       gap: 20px;
    //     }
    //   }
    // }
  }
}

@media screen and (max-width: 992px) {
  .main-wrapper {
    .about-col-section {
      flex-direction: column;

      .col-text {
        &.blue-bg {
          width: 100%;

          .our-courses-box {
            background-color: #152c40;

            .date-title {
              margin: 0 0 0 0;
              height: 100%;
              padding: 24px 12px;

              h1 {
                font-size: 24px;
                line-height: 30px;
                color: #e7f0f4;
                text-align: center;
                margin-bottom: 20px;
              }

              p {
                font-size: 14px;
                line-height: 24px;
                color: #e7f0f4;
                text-align: center;
                margin-bottom: 29px;

              }

              button {
                &.primary-btn {
                  width: 100%;
                  background-color: #e7f0f4;
                  color: #152c40;
                  font-size: 18px;
                  line-height: 23px;

                }

                &::before {
                  content: "";
                  border-right: 50px solid transparent;
                  border-bottom: 50px solid #152c40;
                  transform: translateX(-100%);
                  transition: 0.6s ease-in-out;
                }

                &:hover {
                  color: #e7f0f4;
                  z-index: 9;
                }

                .arrow-icon {
                  background-color: #152c40;
                }
              }
            }
          }
        }
      }

      .col-image {
        width: 100%;
      }
    }

    .about-list-section {
      padding: 35px 0px;

      .about-item-box {
        flex-direction: column-reverse;
        margin-bottom: 15px;
        padding: 0 0;

        &:nth-child(2) {
          flex-direction: column;
        }

        .about-list-box {
          width: 100%;
          padding: 20px 19px;

          h1 {
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 12px;

          }

          p {
            font-size: 12px;
            line-height: 21px;

            margin-bottom: 24px;
            color: #77868d;
          }

          h4 {
            font-size: 12px;
            line-height: 21px;

            margin-bottom: 16px;
          }
        }

        .about-list-img {
          width: 100%;

          img {
            height: 200px;
            object-fit: cover;
          }
        }
      }

      &.choose-us {
        padding: 0;

        .about-choose {
          padding: 16px 0 25px;

          .about-list-choose {
            display: flex;
            overflow: auto;
            margin: 0 -15px;
            padding-left: 15px;

            &::-webkit-scrollbar {
              display: none;
            }
          }

          .choose-us-wrapper {
            margin: 0;
            padding-right: 0;

            .choose-us-box {
              padding-left: 0;
              flex-direction: column;
              margin-right: 12px;
              margin-bottom: 20px;
              overflow: hidden;
              width: 353px;

              .choose-us-content {
                padding: 33px 30px;
                height: 100%;
                background-color: #313a41 !important;

                h2 {
                  padding: 20px 0;
                  color: #aab9c0;

                  &::after {
                    margin: 29px auto 0;
                    background-color: #aab9c0 !important;
                  }
                }

                img {
                  width: 100%;
                  height: 63px;
                  object-fit: contain;
                  filter: none !important;
                }

                p {
                  font-size: 14px;
                  line-height: 21px;

                  color: #aab9c0;
                }
              }

              .choose-us-icon {
                display: block !important;
                width: 100%;
              }
            }
          }
        }
      }
    }

    .about-google-review {
      padding: 23px 0 35px;

      .google-review-wrapper-inner {
        margin: 0 auto 5px;

        .google-content-inner {
          img {
            &.star-icon {
              width: 140px;
            }
          }

          p {
            font-size: 14px;
          }
        }
      }

      .google-review-wrapper {
        padding-top: 20px;
      }

      button {
        &.primary-btn-list {
          width: 100%;
          margin: 32px auto 0;
        }
      }

      .google-review-wrapper-box {
        .google-review-wrapper {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .main-wrapper {
    .about-col-section {
      flex-direction: column;

      .col-text {
        &.blue-bg {
          width: 100%;

          .our-courses-box {
            background-color: #152c40;
            height: 280px;
            width: 101%;

            .date-title {
              margin: 0 0 0 0;
              height: 100%;
              padding: 24px 17px;

              h1 {
                font-size: 24px;
                line-height: 30px;
                color: #e7f0f4;
                text-align: center;
                margin-bottom: 20px;
              }

              p {
                font-size: 14px;
                line-height: 24px;
                color: #e7f0f4;
                text-align: center;
                margin-bottom: 29px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              button {
                &.primary-btn {
                  width: 100%;
                  background-color: #e7f0f4;
                  color: #152c40;
                  font-size: 18px;
                  line-height: 60px;
                  background-image: none;
                  border: 0;

                }

                &::before {
                  content: "";
                  border-right: 50px solid transparent;
                  border-bottom: 50px solid #152c40;
                  transform: translateX(-100%);
                  transition: 0.6s ease-in-out;
                }

                &:hover {
                  color: #e7f0f4;
                  z-index: 9;
                }

                .arrow-icon {
                  background-color: #152c40;
                }
              }
            }
          }

          .swiper {
            .swiper-pagination-bullet {
              background-color: #152c40 !important;
              border: 1px solid #e7f0f4 !important;
              opacity: 1 !important;
            }

            .swiper-pagination-bullet-active {
              background-color: #e7f0f4 !important;
            }

            .swiper-horizontal>.swiper-pagination-bullets,
            .swiper-pagination-bullets.swiper-pagination-horizontal {
              width: 100% !important;
              bottom: 11px !important;
            }
          }
        }
      }

      .col-image {
        width: 100%;
      }
    }

    .about-list-section {
      padding: 35px 0px;

      .about-item-box {
        flex-direction: column-reverse;
        margin-bottom: 15px;
        padding: 0 0;

        &:nth-child(2) {
          flex-direction: column;
        }

        .about-list-box {
          width: 100%;
          padding: 20px 19px;

          h1 {
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 12px;
          }

          p {
            font-size: 12px;
            line-height: 21px;
            margin-bottom: 24px;
            color: #77868d;
          }

          h4 {
            font-size: 12px;
            line-height: 21px;
            margin-bottom: 16px;
          }
        }

        .about-list-img {
          width: 100%;

          img {
            height: 200px;
            object-fit: cover;
          }
        }
      }

      &.choose-us {
        padding: 0;

        .about-choose {
          padding: 16px 0 25px;

          .about-list-choose {
            display: flex;
            overflow: auto;
            margin: 0 -15px;
            padding-left: 15px;

            &::-webkit-scrollbar {
              display: none;
            }
          }

          .choose-us-wrapper {
            margin: 0;
            padding-right: 0;

            .choose-us-box {
              padding-left: 0;
              flex-direction: column;
              margin-right: 12px;
              margin-bottom: 20px;
              overflow: hidden;
              width: 353px;
              height: 525px;

              .choose-us-content {
                padding: 33px 30px;
                height: 100%;
                background-color: #313a41 !important;

                h2 {
                  padding: 20px 0;
                  color: #aab9c0;

                  &::after {
                    margin: 29px auto 0;
                    background-color: #aab9c0 !important;
                  }
                }

                img {
                  width: 100%;
                  height: 63px;
                  object-fit: contain;
                  filter: none !important;
                }

                p {
                  font-size: 14px;
                  line-height: 21px;
                  font-weight: 400;
                  color: #aab9c0;
                }
              }
            }
          }
        }
      }
    }

    .about-google-review {
      padding: 23px 0 35px;

      .google-review-wrapper-inner {
        margin: 0 auto 5px;

        .google-content-inner {
          img {
            &.star-icon {
              width: 140px;
            }
          }

          p {
            font-size: 14px;
          }
        }
      }

      .google-review-wrapper-box {
        padding-left: 12px;
      }

      .google-review-wrapper {
        padding-top: 20px;
      }

      button {
        &.primary-btn-list {
          width: 100%;
          margin: 32px auto 0;
        }
      }
    }
  }
}