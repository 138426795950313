.site-footer {
  .footer-top {
    background-color: #152c40;
    padding: 50px 0;

    .footer-logo {
      margin: 0 auto;
      padding-bottom: 30px;

      img {
        width: 24%;
      }
    }

    .list-unstyled {
      margin: 0 auto;

      li {
        a {
          color: #f3f4f4;
          text-decoration: none;
          font-weight: 600;
          font-size: 13px;
          font-family: "DIN_2014";
          letter-spacing: 1px;

          &:hover {
            color: #808080f6;
          }

          img {
            position: absolute;
            top: 2px;
            left: -9px;
            filter: brightness(0) invert(1);
          }
        }
      }

      &.contents {
        li {
          width: 55%;
          display: flex;
        }
      }
    }
  }

  .footer-bottom {
    background-color: #000000;
    padding: 15px 0;

    .list-inline {
      display: flex;
      justify-content: center;

      li {
        list-style: none;
        margin: 0 0px;
        padding: 0;

        &:nth-child(1)::after {
          content: "";
          width: 7px;
          height: 7px;
          background-color: #c6c6c6;
          display: inline-block;
          margin: 0 5px;
          vertical-align: middle;
          border-radius: 50px;
        }

        
        &:nth-child(2)::after {
          content: "";
          width: 7px;
          height: 7px;
          background-color: #c6c6c6;
          display: inline-block;
          margin: 0 5px;
          vertical-align: middle;
          border-radius: 50px;
        }
        
        &:nth-child(3)::after {
          content: "";
          width: 7px;
          height: 7px;
          background-color: #c6c6c6;
          display: inline-block;
          margin: 0 5px;
          vertical-align: middle;
          border-radius: 50px;
        }

        a {
          color: #c6c6c6;
          text-decoration: none;

          &:hover {
            color: #808080f6;
          }
        }
      }
    }

    p {
      margin-bottom: 15px;
      font-size: 0.9rem;
      line-height: 1;
      font-weight: 400;
      color: #c6c6c6;
      opacity: 1;
    }

    .loyalty-text {
      font-size: 0.9rem;
      line-height: 1;
      color: #c6c6c6;
      opacity: 1;
      margin-bottom: 0;
      font-family: "quincy-cf";
      font-style: normal;
      font-weight: 500;

      em {
        width: 1rem;
        display: inline-block;
        margin: 0 0.5rem;

        img {
          animation: plus-heart 1s infinite;
          -webkit-animation: plus-heart 1s infinite;
        }

        @keyframes plus-heart {
          0% {
            transform: scale(1);
          }

          50% {
            transform: scale(1.3);
          }
        }
      }

      a {
        color: #c6c6c6;
        text-decoration: none;

        &:hover {
          color: #808080f6;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .site-footer {
    .footer-top {
      padding: 33px 0;

      .footer-logo {
        img {
          width: 50%;
        }
      }

      .justify-content-center {
        text-align: center;

        .list-unstyled.contents {
          display: flex;
          justify-content: center;
          flex-direction: column;

          li {
            margin-bottom: 0px;
            margin-right: 0px;

            a {
              font-size: 12px;

              img {
                position: relative;
                top: 0;
                left: 0;
                margin-right: 6px;
              }
            }
          }
        }
      }
    }

    .footer-bottom {
      .list-inline {
        margin-bottom: 10px;
      }

      P {
        line-height: 1.5rem;
        margin-bottom: 11px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .site-footer {
    .footer-top {
      padding: 33px 0;

      .footer-logo {
        img {
          width: 50% !important;
        }
      }

      .justify-content-center {
        text-align: center;

        .list-unstyled.contents {
          display: flex;
          justify-content: center;
          flex-direction: column;

          li {
            margin-bottom: 0px;
            margin-right: 0px;
            width: 100%;
            justify-content: center;

            a {
              font-size: 12px;

              img {
                position: relative;
                top: 0;
                left: 0;
                margin-right: 6px;
              }
            }
          }
        }
      }
    }

    .footer-bottom {
      .list-inline {
        margin-bottom: 10px;
      }

      P {
        line-height: 1.5rem;
        margin-bottom: 11px;
      }
    }
  }
}

@media screen and (max-device-width: 555px) {
  .site-footer {
    .footer-top {
      padding: 33px 0;

      .footer-logo {
        img {
          width: 50% !important;
        }
      }

      .justify-content-center {
        text-align: center;

        .list-unstyled.contents {
          flex-direction: row;

          li {
            margin-bottom: 16px;
            margin-right: 3px;
            width: 100%;
            justify-content: center;

            a {
              font-size: 12px;

              img {
                position: relative;
                top: 0;
                left: 0;
                margin-right: 6px;
              }
            }
          }
        }
      }
    }

    .footer-bottom {
      .list-inline {
        margin-bottom: 10px;
      }

      P {
        line-height: 1.5rem;
        margin-bottom: 11px;
      }
    }
  }
}

@media screen and (max-device-width: 480px) {
  .site-footer {
    .footer-top {
      padding: 33px 0;

      .footer-logo {
        img {
          width: 50% !important;
        }
      }

      .justify-content-center {
        text-align: center;

        .list-unstyled.contents {
          flex-direction: row;

          li {
            margin-bottom: 16px;
            margin-right: 3px;
            width: 100%;
            justify-content: center;

            a {
              font-size: 12px;

              img {
                position: relative;
                top: 0;
                left: 0;
                margin-right: 6px;
              }
            }
          }
        }
      }
    }

    .footer-bottom {
      .list-inline {
        margin-bottom: 10px;
      }

      P {
        line-height: 1.5rem;
        margin-bottom: 11px;
      }
    }
  }
}

@media screen and (max-device-width: 390px) {
  .site-footer {
    .footer-top {
      padding: 33px 0;

      .footer-logo {
        img {
          width: 50% !important;
        }
      }

      .justify-content-center {
        text-align: center;

        .list-unstyled.contents {
          flex-direction: row;

          li {
            margin-bottom: 16px;
            margin-right: 3px;
            width: 100%;
            justify-content: center;

            a {
              font-size: 11px;

              img {
                position: relative;
                top: 0;
                left: 0;
                margin-right: 6px;
              }
            }
          }
        }
      }
    }

    .footer-bottom {
      .list-inline {
        margin-bottom: 10px;
      }

      P {
        line-height: 1.5rem;
        margin-bottom: 11px;
      }
    }
  }
}

@media screen and (max-device-width: 360px) {
  .site-footer {
    .footer-top {
      padding: 33px 0;

      .footer-logo {
        img {
          width: 75%;
        }
      }

      .justify-content-center {
        text-align: center;

        .list-unstyled.contents {
          flex-direction: row;

          li {
            margin-bottom: 16px;
            margin-right: 3px;
            width: 100%;
            justify-content: center;

            a {
              font-size: 12px;

              img {
                position: relative;
                top: 0;
                left: 0;
                margin-right: 6px;
              }
            }
          }
        }
      }
    }

    .footer-bottom {
      .list-inline {
        margin-bottom: 10px;
      }

      P {
        line-height: 1.5rem;
        margin-bottom: 11px;
      }
    }
  }
}