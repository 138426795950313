.main-wrapper {
  .car-listing-section {
    padding: 55px 0;

    .modern-filter-listing {
      display: flex;

      .car-listing-left {
        width: 30%;
        margin-right: 50px;
        height: 100%;
        background-color: #e7f0f4;
        border-radius: 5px;
        padding: 27px 25px;

        .accordion {
          border: 0;

          .accordion-item {
            border: 0;
            border-radius: 5px;
            margin-bottom: 15px;
            box-shadow: 0px 3px 6px #0000000d;

            &:nth-child(3) {
              .accordion-header {
                .accordion-button {
                  .car-listing-icon {
                    img {
                      width: 18px;
                      height: 18px;
                    }
                  }
                }
              }
            }

            &:nth-child(9) {
              .accordion-header {
                .accordion-button {
                  .car-listing-icon {
                    line-height: 12px;

                    img {
                      width: 13px;
                      height: 13px;
                    }
                  }
                }
              }
            }

            &:nth-child(7) {
              .accordion-collapse {
                .accordion-body {
                  .sort-list {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    justify-content: center;
                    gap: 30px;
                  }
                }
              }
            }

            &:nth-child(8) {
              .accordion-header {
                .accordion-button {
                  .car-listing-icon {
                    line-height: 12px;
                    align-items: end;
                  }
                }
              }
            }

            &:nth-child(8) {
              .accordion-collapse {
                .accordion-body {
                  .sort-list {
                    justify-content: flex-start;
                    display: grid;
                    gap: 15px;
                    grid-template-columns: repeat(2, 1fr);
                  }
                }
              }
            }

            .accordion-header {
              .accordion-button {
                border-radius: 0px;
                box-shadow: 0px 0px 0px #0000000d;
                padding: 13px 15px;
                background-color: transparent;

                .car-listing-icon {
                  font-size: 12px;
                  font-family: "DIN_2014";
                  line-height: 14px;
                  display: flex;
                  align-items: center;
                  font-weight: 600;
                  color: #152c40;

                  img {
                    width: 13px;
                    margin-right: 10px;
                    height: 13px;
                    object-fit: contain;
                  }
                }

                &:focus {
                  box-shadow: none;
                }

                &::after {
                  background-image: url(../images/arrow-down.svg);
                  width: 20px;
                  height: 12px;
                  object-fit: contain;
                  background-size: 14px;
                  position: absolute;
                  right: 14px;
                  top: 11px;
                }

                &.collapsed {
                  &::after {
                    background-image: url(../images/arrow-down.svg);
                    width: 20px;
                    height: 12px;
                    object-fit: contain;
                    background-size: 14px;
                    position: absolute;
                    right: 9px;
                    top: 15px;
                  }
                }
              }
            }

            .accordion-collapse {
              .accordion-body {
                padding: 5px 15px 25px;

                .options {
                  display: flex;

                  label {
                    margin-right: 50px;
                    font-size: 14px;

                    margin-bottom: 0;
                    display: flex;
                    align-items: center;

                    &:last-child {
                      margin-right: 0;
                    }

                    input {
                      margin-right: 10px;
                    }

                    input[type="radio"] {
                      appearance: none;
                      width: 16px;
                      height: 16px;
                      border: 1.5px solid #152c40;
                      border-radius: 50%;
                      outline: none;
                      cursor: pointer;
                      transition: background-color 0.3s ease,
                        border-color 0.3s ease;
                    }

                    input[type="radio"]:checked {
                      border-color: #152c40;
                      position: relative;
                    }

                    input[type="radio"]:checked::before {
                      content: "";
                      display: flex;
                      width: 8px;
                      height: 8px;
                      background-color: #152c40;
                      border-radius: 50%;
                      position: absolute;
                      left: 50%;
                      right: 0;
                      top: 50%;
                      margin: 0 auto;
                      transform: translate(-50%, -50%);
                    }
                  }
                }

                .sort-list {
                  display: flex;
                  justify-content: space-between;

                  .sort-by {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px 35px;
                    flex-direction: column;

                    label {
                      //   margin-right: 50px;
                      font-size: 12px;
                      font-family: "DIN_2014";
                      margin-bottom: 0;
                      display: flex;
                      align-items: center;
                      font-weight: 400;

                      //   &:last-child {
                      //     margin-right: 0;
                      //   }
                      input {
                        margin-right: 10px;
                      }

                      input[type="radio"] {
                        appearance: none;
                        width: 16px;
                        height: 16px;
                        border: 1.5px solid #152c40;
                        border-radius: 50%;
                        outline: none;
                        cursor: pointer;
                        transition: background-color 0.3s ease,
                          border-color 0.3s ease;
                      }

                      input[type="radio"]:checked {
                        border-color: #152c40;
                        position: relative;
                        font-family: "DIN_2014";
                        font-weight: 600;
                      }

                      input[type="radio"]:checked::before {
                        content: "";
                        display: flex;
                        width: 8px;
                        height: 8px;
                        background-color: #152c40;
                        border-radius: 50%;
                        position: absolute;
                        left: 50%;
                        right: 0;
                        top: 50%;
                        margin: 0 auto;
                        transform: translate(-50%, -50%);
                      }
                    }

                    // .round-checkbox {
                    //   display: flex;
                    //   align-items: center;
                    //   cursor: pointer;
                    //   position: relative;
                    //   span {
                    //     display: flex;
                    //   }
                    // }

                    // .round-checkbox input {
                    //   display: none; /* Hide the default checkbox */
                    // }

                    // .round-checkbox span::before {
                    //   content: "";
                    //   display: inline-block;
                    //   width: 16px;
                    //   height: 16px;
                    //   border: 1px solid #555;
                    //   border-radius: 50%; /* Makes the shape round */
                    //   margin-right: 8px;
                    //   background-color: white;
                    //   transition: background 0.3s ease, border-color 0.3s ease;
                    // }

                    // .round-checkbox input:checked + span::before {
                    //   background-color: #fff; /* Blue background when checked */
                    //   border-color: #152c40;
                    // }

                    // .round-checkbox input:checked + span::after {
                    //   content: "";
                    //   position: absolute;
                    //   width: 8px;
                    //   height: 8px;
                    //   background: #152c40;
                    //   border-radius: 50%; /* Inner circle */
                    //   top: 0%;
                    //   left: 0%;
                    //   transform: translate(50%, 50%);
                    // }
                  }
                }

                .range-slider {
                  width: 100%;
                  height: 8px;
                  background-color: #e7f0f4;
                  border-radius: 5px;
                  position: relative;
                }

                .thumb {
                  height: 16px;
                  width: 16px;
                  background-color: #e6f0f3;
                  border: 3.5px solid #152c40;
                  border-radius: 50%;
                  cursor: grab;
                  top: -4px;
                  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
                }

                .track {
                  background-color: #e7f0f4;
                  height: 8px;
                  border-radius: 4px;

                  &.track-1 {
                    background-color: #152c40;
                    height: 8px;
                    border-radius: 4px;
                  }
                }

                .slider-labels {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 10px;
                  font-size: 12px;
                  color: #152c40;
                  font-family: "DIN_2014";
                  font-weight: 400;
                }

                .slider-values {
                  display: flex;
                  justify-content: space-between;
                  margin-top: 10px;
                  font-size: 12px;
                  font-family: "DIN_2014";
                  font-weight: 600;
                }
              }
            }
          }
        }

        .filter-header {
          display: none;
        }
      }

      .car-listing-right {
        width: 69%;

        .car-listing-right-top {
          .car-listing-right-top-left {
            margin-bottom: 24px;

            .car-listing-right-top-left-text {
              display: flex;
              align-items: center;
              justify-content: space-between;

              h1 {
                font-size: 21px;
                margin-bottom: 0;
                line-height: 27px;
                font-family: "DIN_2014";
                font-weight: 600;
              }

              .car-listing-right-top-left-icon {
                display: flex;

                div {
                  cursor: pointer;
                  margin-right: 19px;

                  img {
                    width: 24px;
                    height: 24px;
                    object-fit: contain;
                  }

                  &.icon.inactive {
                    opacity: 0.5;
                    // cursor: not-allowed;
                  }

                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
            }
          }

          a {
            text-decoration: none;

            .blog-listing-box {
              background-color: #f3f4f4;
              margin-bottom: 25px;
              cursor: pointer;

              .blog-listing-img {
                img {
                  width: 100%;
                  height: 250px;
                  object-fit: cover;
                }
              }

              .blog-listing-text {
                padding: 26px 18px;

                h1 {
                  color: #313a41;
                  font-size: 16px;
                  line-height: 20px;
                  font-family: "DIN_2014";
                  margin-bottom: 13px;
                  font-weight: 600;
                }

                ul {
                  padding: 0;
                  list-style: none;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  li {
                    a {
                      color: #313a41;
                      font-size: 12px;
                      font-family: "DIN_2014";
                      display: flex;
                      font-weight: 600;

                      img {
                        width: 13px;
                        height: 13px;
                        object-fit: contain;
                        margin-right: 5px;
                        vertical-align: sub;
                      }
                    }

                    // &:nth-child(3) {
                    //   a {
                    //     display: -webkit-box;
                    //     // -webkit-line-clamp: 1;
                    //     -webkit-box-orient: vertical;
                    //     overflow: hidden;
                    //     text-overflow: ellipsis;
                    //   }
                    // }

                    &:nth-child(4) {
                      a {
                        img {
                          width: 42%;
                          margin-top: 1px;
                        }
                      }
                    }
                  }
                }

                .grop-btn {
                  display: flex;

                  button {
                    position: relative;
                    display: flex;
                    overflow: hidden;
                    // width: 100%;
                    border: 2px solid #152c40;
                    box-shadow: 0px 3px 6px #0000001a;
                    background-color: #ffffff;
                    color: #152c40;
                    border-radius: 5px;
                    font-size: 12px;
                    line-height: 20px;
                    text-align: center;
                    font-weight: 600;
                    font-family: "DIN_2014";
                    transition: 0.6s ease-in-out;
                    padding: 9px 22px;
                    justify-content: center;
                    align-items: center;
                    margin-right: 15px;
                    width: 100%;

                    &:last-child {
                      margin-right: 0;
                    }

                    img {
                      transition: 0.9s ease-in-out;
                      margin-right: 6px;
                      width: 16px;
                      height: 14px;
                      object-fit: contain;
                    }

                    &.active {
                      background-image: linear-gradient(101deg,
                          #202a50 0%,
                          #0e77bd 50%,
                          #073c5f 100%);
                      color: #e7f0f4;
                      z-index: 9;
                      border: 0px solid;
                      height: 42px;
                      // border-image-slice: 1;
                      border-image-source: linear-gradient(101deg,
                          #202a50 0%,
                          #0e77bd 50%,
                          #073c5f 100%);

                      img {
                        filter: brightness(0) invert(1);
                        transition: 0.9s ease-in-out;
                      }
                    }

                    &:hover {
                      color: #e7f0f4;
                      z-index: 9;
                      border: 0px solid;
                      border-image-slice: 1;
                      border-image-source: linear-gradient(101deg,
                          #202a50 0%,
                          #0e77bd 50%,
                          #073c5f 100%);
                      background-image: linear-gradient(101deg,
                          #202a50 0%,
                          #0e77bd 50%,
                          #073c5f 100%);
                      transition: 0.6s ease-in-out;
                      height: 42px;

                      img {
                        filter: brightness(0) invert(1);
                        transition: 0.9s ease-in-out;
                      }
                    }
                  }
                }
              }
            }
          }

          .list-row {
            display: block;

            a {
              .blog-listing-box {
                display: flex;
                height: 200px;

                .blog-listing-img {
                  width: 50%;

                  img {
                    height: 200px;
                  }
                }

                .blog-listing-text {
                  padding: 38px 25px;
                  width: 60%;

                  h1 {
                    margin-bottom: 20px;
                  }

                  ul {
                    margin-bottom: 25px;
                  }
                }
              }
            }

            .list-vertical {
              padding: 0;
            }
          }
        }

        .stm-accordion-single-unit {
          .accordion {
            border: 0;
            --bs-accordion-bg: #ffffff00;

            .accordion-item {
              border-bottom: 1px solid #e0e0e0;
              border-top: 0;
              border-left: 0;
              border-right: 0;
              border-radius: 0;

              .accordion-header {
                .accordion-button {
                  font-family: "DIN_2014";
                  font-size: 21px;
                  line-height: 27px;
                  color: #152c40;
                  background-color: transparent;
                  box-shadow: none;
                  padding: 20px 0;
                  font-weight: 900;

                  &.collapsed {
                    &::after {
                      background-image: url(../images/plus_icon.svg);
                      width: 16px;
                      height: 16px;
                      background-size: 16px;
                    }
                  }

                  &::after {
                    background-image: url(../images/minus_icon.svg);
                    height: 3px;
                    width: 16px;
                  }
                }
              }

              .accordion-collapse {
                .accordion-body {
                  padding: 0 0px 25px;
                  font-size: 15px;
                  line-height: 30px;
                  font-family: "DIN_2014";
                  font-weight: 400;
                  color: #152c40;

                  .information {
                    display: block;
                    padding-top: 11px;

                    .addi-information {
                      margin-right: 55px;
                      width: 100%;
                      display: grid;
                      gap: 0;
                      grid-template-columns: repeat(3, 1fr);

                      &:last-child {
                        margin-right: 0;
                      }

                      .box {
                        margin-bottom: 30px;

                        .information-text {
                          display: flex;
                          align-items: center;
                          font-size: 16px;
                          font-family: "DIN_2014";
                          font-weight: 600;

                          p {
                            margin-bottom: 0;
                            font-size: 16px;
                            line-height: 20px;
                            font-family: "DIN_2014";
                            font-weight: 600;
                          }

                          img {
                            margin-right: 15px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .swiper-container {
          width: 100%;
          margin: 14px auto;
          position: relative;

          .swiper-button-prev {
            color: #313a41;
            position: absolute;

            &::after {
              position: absolute;
              left: -46px;
              font-size: 14px;
              font-weight: 700;
            }

            &.swiper-button-disabled {
              opacity: 100%;
            }
          }

          .swiper-button-next {
            color: #313a41;
            position: absolute;

            &::after {
              position: absolute;
              right: -46px;
              font-size: 14px;
              font-weight: 700;
            }

            &.swiper-button-disabled {
              opacity: 100%;
            }
          }

          .swiper {
            margin-left: auto;
            margin-right: auto;
            position: relative;
            overflow: hidden;
            list-style: none;
            padding: 0;
            z-index: 0;
            display: block;

            .swiper-wrapper {
              margin-left: 0px;

              .swiper-slide {
                img {
                  width: 100%;
                  height: 160px;
                  object-fit: cover;
                }
              }
            }
          }
        }

        .car-list-img {
          img {
            width: 100%;
            height: 500px;
            margin-bottom: 20px;
            object-fit: cover;
          }
        }
      }
    }

    &.car-details {
      .modern-filter-listing {
        .car-listing-left {
          h5 {
            background-image: linear-gradient(101deg,
                #202a50 0%,
                #0e77bd 50%,
                #073c5f 100%);
            color: #e7f0f4;
            text-align: center;
            font-size: 21px;
            line-height: 27px;
            font-family: "DIN_2014";
            width: 100%;
            height: 50px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;
            cursor: pointer;
            font-weight: 900;
          }

          .details-box {
            background-color: #ffffff;
            border-radius: 5px;
            box-shadow: 0px 0px 6px #0000000d;
            margin-bottom: 15px;
            padding: 26px 25px;

            .inner-title-text {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid #aab9c0;
              padding: 18px 0;

              &:last-child {
                border-bottom: 0;
                padding: 18px 0 8px;
              }

              h3 {
                font-size: 11px;
                color: #77868d;
                font-family: "DIN_2014";
                line-height: 11px;
                margin-bottom: 0;
                font-weight: 600;
              }

              p {
                font-size: 11px;
                color: #152c40;
                font-family: "DIN_2014";
                line-height: 11px;
                margin-bottom: 0;
                font-weight: 600;
              }
            }

            h2 {
              font-size: 12px;
              color: #152c40;

              line-height: 14px;
              margin-bottom: 17px;
            }

            ul {
              padding: 0;
              list-style: none;

              li {
                font-size: 10px;
                border: 1px solid #77868d;
                border-radius: 5px;
                width: 100%;
                height: 30px;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                color: #77868d;

                padding: 0 14px;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }

            button {
              &.primary-btn {
                background-color: #fff;
                border: 2px solid #152c40;
                border-radius: 5px;
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                line-height: 15px;
                color: #152c40;
                font-family: "DIN_2014";
                font-weight: 600;

                &:hover {
                  background-color: #152c40;
                  color: #e7f0f4;
                }
              }
            }
          }
        }

        .car-listing-right {
          .car-listing-right-top-left-text {
            h1 {
              font-size: 34px;
              line-height: 50px;
              color: #152c40;
              font-family: "DIN_2014";
              margin-bottom: 35px;
              font-weight: 600;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .car-top-section {
              position: relative;

              ul {
                padding: 0;
                margin: 0;
                list-style: none;
                display: flex;
                position: absolute;
                top: -31px;
                width: 100%;

                li {
                  padding: 0 10px;

                  a {
                    font-size: 14px;
                    text-decoration: none;
                    color: #202a50;
                  }
                }
              }
            }
          }

          .group-btn {
            display: flex;
            margin: 50px 0 30px;

            button {
              position: relative;
              display: flex;
              overflow: hidden;
              border: 2px solid #152c40;
              box-shadow: 0px 3px 6px #0000001a;
              background-color: #ffffff;
              color: #152c40;
              border-radius: 5px;
              font-size: 12px;
              line-height: 20px;
              text-align: center;
              font-weight: 600;
              font-family: "DIN_2014";
              transition: 0.6s ease-in-out;
              padding: 9px 22px;
              justify-content: center;
              align-items: center;
              margin-right: 15px;
              width: 20%;

              &:last-child {
                margin-right: 0;
              }

              img {
                transition: 0.9s ease-in-out;
                margin-right: 6px;
                width: 16px;
                height: 14px;
                object-fit: contain;
              }

              &.active {
                background-image: linear-gradient(101deg,
                    #202a50 0%,
                    #0e77bd 50%,
                    #073c5f 100%);
                color: #e7f0f4;
                z-index: 9;
                border: 0px solid;
                border-image-slice: 1;
                height: 42px;
                border-image-source: linear-gradient(101deg,
                    #202a50 0%,
                    #0e77bd 50%,
                    #073c5f 100%);

                img {
                  filter: brightness(0) invert(1);
                  transition: 0.9s ease-in-out;
                }
              }

              &:hover {
                color: #e7f0f4;
                z-index: 9;
                border: 0px solid;
                border-image-slice: 1;
                height: 42px;
                border-image-source: linear-gradient(101deg,
                    #202a50 0%,
                    #0e77bd 50%,
                    #073c5f 100%);
                background-image: linear-gradient(101deg,
                    #202a50 0%,
                    #0e77bd 50%,
                    #073c5f 100%);
                transition: 0.6s ease-in-out;

                img {
                  filter: brightness(0) invert(1);
                  transition: 0.9s ease-in-out;
                }
              }
            }
          }

          .car-list-btn {
            display: flex;
            margin-bottom: 38px;

            button {
              &.primary-btn {
                border: 1px solid #152c40;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 25%;
                height: 40px;
                background-color: #ffffff;
                margin-right: 18px;
                transition: 1s;

                &:last-child {
                  margin-right: 0;
                }

                &:hover {
                  border: 0px solid;
                  border-image-slice: 1;
                  border-image-source: linear-gradient(101deg,
                      #202a50 0%,
                      #0e77bd 50%,
                      #073c5f 100%);
                  background-image: linear-gradient(101deg,
                      #202a50 0%,
                      #0e77bd 50%,
                      #073c5f 100%);
                  transition: 1s;
                  color: #fff;

                  span {
                    color: #e7f0f4;
                  }

                  img {
                    filter: brightness(0) invert(1);
                  }
                }

                img {
                  margin-right: 6px;
                  width: 18px;
                  height: 18px;
                  object-fit: contain;
                }

                span {
                  font-size: 12px;
                  font-family: "DIN_2014";
                  color: #152c40;
                  height: 15px;
                  font-weight: 600;
                }
              }
            }
          }

          p {
            font-size: 18px;
            line-height: 30px;
            font-family: "DIN_2014";
            color: #152c40;
            margin-bottom: 30px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.schedule-modal {
  .modal-dialog {
    .modal-content {
      border: 0;
      background-color: #f3f4f4;
      position: relative;

      .modal-header {
        border-bottom: 0;

        .btn-close {
          box-shadow: none;
          opacity: 1;
          width: 18px;
          height: 18px;
          background-size: 14px;
        }
      }

      .modal-body {
        padding: 8px 25px 35px;

        .schedule-container {
          text-align: center;

          img {
            padding-bottom: 12px;
          }

          h1 {
            font-size: 30px;
            line-height: 43px;
            font-family: "DIN_2014";
            margin-bottom: 18px;
            color: #152c40;
            font-weight: 900;
          }

          p {
            font-size: 17px;
            line-height: 25px;
            font-family: "DIN_2014";
            color: #152c40;
            margin-bottom: 30px;
            background-color: #e7f0f4;
            border-radius: 5px;
            padding: 15px 0;
            border: 1px solid #77868d;
            font-weight: 900;
          }

          .schedule-form {
            form {
              .form-group {
                margin-bottom: 20px;

                .form-control {
                  border-radius: 5px;
                  border: 1px solid #77868d;
                  box-shadow: 0px 0px 3px #00000029;
                  height: 50px;
                  padding: 0 20px;
                  font-size: 14px;
                  line-height: 25px;
                  color: #77868d;
                  font-weight: 500;
                  font-family: "DIN_2014";
                  background-color: #fff;
                  margin-bottom: 0px;

                  &::placeholder {
                    font-size: 14px;
                    line-height: 25px;
                    color: #77868d;
                    font-weight: 500;
                  }
                }

                .error-message {
                  text-align: left;
                }
              }

              button {
                &.primary-btn {
                  background-image: linear-gradient(96deg,
                      #202a50 0%,
                      #0e77bd 50%,
                      #073c5f 100%);
                  border: 0px solid #152c40;
                  border: 0px solid;
                  border-image-slice: 1;
                  border-image-source: linear-gradient(107deg,
                      #202a50 0%,
                      #0e77bd 50%,
                      #073c5f 100%);
                  border-radius: 5px;
                  width: 100%;
                  height: 50px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 17px;
                  line-height: 20px;
                  color: #ffffff;
                  font-family: "DIN_2014";
                  margin-top: 35px;
                  font-weight: 900;

                  &:hover {
                    background-color: #fff;
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .main-wrapper {
    .car-listing-section {
      padding: 30px 0;

      .modern-filter-listing {
        .car-listing-left {
          width: 40%;
          margin-right: 30px;
        }

        .car-listing-right {
          width: 69%;

          .car-listing-right-top {
            .list-row {
              a {
                .blog-listing-box {
                  height: auto;

                  .blog-listing-img {
                    img {
                      height: 100%;
                    }
                  }

                  .blog-listing-text {
                    ul {
                      align-items: flex-start;
                      flex-direction: column;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .schedule-modal {
    .modal-dialog {
      .modal-content {
        .modal-header {
          padding-bottom: 0;

          .btn-close {
            width: 12px;
            height: 12px;
            background-size: 12px;
          }
        }

        .modal-body {
          padding: 0px 14px 23px;

          .schedule-container {
            img {
              padding-bottom: 7px;
              width: 15%;
              margin: 0 auto;
            }

            h1 {
              font-size: 19px;
              line-height: 24px;
              margin-bottom: 13px;
            }

            p {
              font-size: 12px;
              line-height: 14px;
              margin-bottom: 19px;
              padding: 10px 0;
            }

            .schedule-form {
              form {
                .form-control {
                  font-size: 12px;
                  line-height: 13px;
                  margin-bottom: 13px;
                  height: 35px;

                  &::placeholder {
                    font-size: 12px;
                    line-height: 13px;
                    margin-bottom: 13px;
                  }
                }

                button {
                  &.primary-btn {
                    height: 35px;
                    font-size: 10px;
                    line-height: 13px;

                    margin-top: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .main-wrapper {
    .car-listing-section {
      padding: 30px 0;

      .modern-filter-listing {
        flex-direction: column-reverse;

        .car-listing-left {
          width: 100%;
          margin-right: 30px;
        }

        .car-listing-right {
          width: 100%;

          .car-listing-right-top {
            .list-row {
              a {
                .blog-listing-box {
                  height: auto;

                  .blog-listing-img {
                    img {
                      height: 100%;
                    }
                  }

                  .blog-listing-text {
                    ul {
                      align-items: flex-start;
                      flex-direction: row;
                    }
                  }
                }
              }
            }

            .car-listing-right-top-left {
              margin-bottom: 3px;

              .car-listing-right-top-left-text {
                h1 {
                  line-height: 80px;
                }
              }
            }
          }

          .car-listing-right-top-left-text {
            h1 {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .schedule-modal {
    .modal-dialog {
      .modal-content {
        .modal-header {
          padding-bottom: 0;

          .btn-close {
            width: 12px;
            height: 12px;
            background-size: 12px;
          }
        }

        .modal-body {
          padding: 0px 14px 23px;

          .schedule-container {
            img {
              padding-bottom: 7px;
              width: 15%;
              margin: 0 auto;
            }

            h1 {
              font-size: 19px;
              line-height: 24px;
              margin-bottom: 13px;
            }

            p {
              font-size: 12px;
              line-height: 14px;
              margin-bottom: 19px;
              padding: 10px 0;
            }

            .schedule-form {
              form {
                .form-control {
                  font-size: 12px;
                  line-height: 13px;
                  margin-bottom: 13px;
                  height: 35px;

                  &::placeholder {
                    font-size: 12px;
                    line-height: 13px;
                    margin-bottom: 13px;
                  }
                }

                button {
                  &.primary-btn {
                    height: 35px;
                    font-size: 10px;
                    line-height: 13px;

                    margin-top: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .main-wrapper {
    .car-listing-section {
      padding: 30px 0 15px;

      .modern-filter-listing {
        display: block;
        overflow: hidden;

        .car-listing-left {
          width: 100%;
          display: none;
        }

        .car-listing-left.open {
          display: block;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 1060;
          height: 100%;
          overflow: auto;
          background-color: #e6f0f3;

          .filter-header {
            display: block;

            .filter-close {
              margin-bottom: 27px;
            }
          }

          .accordion {
            .accordion-item {
              &:nth-child(8) {
                .accordion-collapse {
                  .accordion-body {
                    .sort-list {
                      gap: 28px;
                    }
                  }
                }
              }
            }
          }
        }

        .car-listing-right {
          width: 100%;

          .car-listing-right-top {
            a {
              .blog-listing-box {
                .blog-listing-text {
                  padding: 16px 10px 20px;
                  position: relative;

                  .grop-btn {
                    justify-content: center;

                    a {
                      &.btn-primary {
                        width: 100%;
                      }
                    }
                  }

                  ul {
                    align-items: flex-start;
                    li {
                      &:nth-child(5) {
                        position: absolute;
                        top: 16px;
                        right: 17px;
                      }

                      a {
                        font-size: 0.7rem;

                        img {
                          width: 22px;
                        }
                      }
                    }
                  }
                }
              }
            }

            .car-listing-right-top-left {
              margin-bottom: 30px;

              .car-listing-right-top-left-text {
                flex-direction: column;
                align-items: flex-start;
                width: 100%;

                h1 {
                  width: 100%;
                  text-align: center;
                  font-size: 24px;
                  line-height: 31px;
                  margin-bottom: 21px;
                }

                .filter-btn {
                  button {
                    background-color: #152c40;
                    color: #e7f0f4;
                    border: 0;
                    border-radius: 5px;
                    width: 125px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    font-size: 12px;
                    font-family: "DIN_2014";
                    line-height: 9px;
                    padding: 15px 5px;
                    letter-spacing: 1px;
                    font-weight: 600;

                    img {
                      vertical-align: baseline;
                    }

                    span {
                      font-size: 12px;
                    }
                  }
                }
              }
            }

            .list-row {
              a {
                .blog-listing-box {
                  flex-direction: column;

                  .blog-listing-img {
                    width: 100%;

                    img {
                      width: 100%;
                      object-fit: cover;
                      height: 200px;
                    }
                  }

                  .blog-listing-text {
                    width: 100%;
                    padding: 16px 16px 20px;
                    position: relative;
                  }
                }
              }
            }
          }

          .swiper-container {
            .swiper {
              .swiper-wrapper {
                margin: 0 0px;

                .swiper-slide {
                  img {
                    width: 100%;
                    height: 95px;
                    object-fit: cover;
                  }
                }
              }

              .swiper-button-prev {
                display: none;
              }

              .swiper-button-next {
                display: none;
              }
            }
          }

          .stm-accordion-single-unit {
            .accordion {
              .accordion-item {
                .accordion-collapse {
                  .accordion-body {
                    .information {
                      display: block;
                      padding-top: 11px;

                      .addi-information {
                        margin-right: 0px;
                        width: 100%;
                        display: grid;
                        gap: 0;
                        grid-template-columns: repeat(3, 1fr);

                        &:last-child {
                          margin-right: 0;
                        }

                        .box {
                          margin-bottom: 15px;

                          .information-text {
                            font-size: 12px;

                            p {
                              margin-bottom: 0;
                              font-size: 12px;
                              line-height: 16px;
                            }

                            img {
                              margin-right: 5px;
                              width: 18px;
                              height: 18px;
                              object-fit: contain;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .car-list-img {
            img {
              height: 100%;
              margin-bottom: 0;
              object-fit: contain;
            }
          }
        }
      }

      &.car-details {
        .modern-filter-listing {
          flex-direction: column-reverse;
          display: flex;

          .car-listing-left {
            margin-right: 0;
            margin-bottom: 20px;
            margin-top: 45px;
            display: block;
          }

          .car-listing-right {
            .car-listing-right-top-left-text {
              h1 {
                font-size: 24px;
                line-height: 31px;
                margin-bottom: 22px;
                text-align: center;
                -webkit-line-clamp: 2;
              }

              .car-top-section {
                ul {
                  top: 0px;
                  position: relative;
                  margin-bottom: 10px;

                  li {
                    a {
                      font-size: 13px;
                    }

                    &:nth-child(2) {
                      padding: 0 0 0 5px;
                      width: 90px;
                    }

                    &:nth-child(3) {
                      a {
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        line-height: 28px;
                      }
                    }
                  }
                }
              }
            }

            .group-btn {
              margin: 30px 0 20px;

              button {
                width: 100%;
              }
            }

            .car-list-btn {
              margin-bottom: 30px;

              button {
                &.primary-btn {
                  width: 100%;
                  margin-right: 12px;
                  font-size: 9px;
                  span{
                    font-size: 11px;
                  }
                }
              }
            }

            .stm-accordion-single-unit {
              .accordion {
                .car-listing-left-accordion {
                  border-bottom: 0px solid #e0e0e0;

                  .accordion-collapse {
                    .accordion-body {
                      .car-listing-left {
                        margin-top: 0;
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .schedule-modal {
    .modal-dialog {
      position: fixed;
      top: 0;
      left: 0;
      width: 96%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;

      .modal-content {
        border-radius: 10px;
        // top: 25%;
        // margin: 150px 0;
        // width: 75%;
        // position: fixed;

        .modal-header {
          padding-bottom: 0;

          .btn-close {
            width: 12px;
            height: 12px;
            background-size: 12px;
          }
        }

        .modal-body {
          padding: 0px 14px 23px;

          .schedule-container {
            img {
              padding-bottom: 7px;
              width: 15%;
              margin: 0 auto;
            }

            h1 {
              font-size: 19px;
              line-height: 24px;
              margin-bottom: 13px;
            }

            p {
              font-size: 12px;
              line-height: 14px;
              margin-bottom: 19px;
              padding: 10px 0;
            }

            .schedule-form {
              form {
                .form-control {
                  font-size: 12px;
                  line-height: 13px;
                  margin-bottom: 13px;
                  height: 35px;

                  &::placeholder {
                    font-size: 12px;
                    line-height: 13px;
                    margin-bottom: 13px;
                  }
                }

                button {
                  &.primary-btn {
                    height: 35px;
                    font-size: 10px;
                    line-height: 13px;

                    margin-top: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-device-width: 430px) {
  .main-wrapper .car-listing-section.car-details .modern-filter-listing .car-listing-right .car-listing-right-top-left-text .car-top-section ul li:nth-child(2) {
    padding: 0 0 0 5px;
  }
}

@media screen and (max-device-width: 390px) {
  .main-wrapper .car-listing-section.car-details .modern-filter-listing .car-listing-right .car-listing-right-top-left-text .car-top-section ul li:nth-child(2) {
    padding: 0 0 0 5px;
    width: 100px;

    &:nth-child(1) {
      a {
        width: 66px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

  }
}