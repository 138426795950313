.main-wrapper {
  .home-col-section {
    display: flex;
    flex-direction: row-reverse;

    .col-text.blue-bg {
      background-color: #e7f0f4;
      box-shadow: 0px 0px 6px #0000001a;
      width: 50%;
      height: 695px;

      .swiper {
        width: 100%;
        height: 100%;

        .swiper-slide img {
          width: 100%;
          height: auto;
          display: block;
        }

        .swiper-pagination-bullet {
          background-color: #e7f0f4 !important;
          border: 1px solid #152c40 !important;
          opacity: 1 !important;
        }

        .swiper-pagination-bullet-active {
          background-color: #152c40 !important;
        }

        .swiper-horizontal>.swiper-pagination-bullets,
        .swiper-pagination-bullets.swiper-pagination-horizontal {
          width: 37% !important;
          bottom: 43px !important;
        }
      }

      .our-courses-box {
        padding: 123px 80px;

        .date-inner-title {
          .date-title {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            position: absolute;
            left: 16%;
            top: 0;
            height: 70%;
            width: 68%;
          }

          h2 {
            font-size: 3.5rem;
            font-weight: 600;
            line-height: 1.2;
            color: #313a41;
            margin-bottom: 30px;
            font-family: "DIN_2014";
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          p {
            font-size: 21px;
            font-weight: 300;
            line-height: 33px;
            color: #77868d;
            margin-bottom: 50px;
            font-family: "DIN_2014";
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          button {
            &.primary-btn {
              position: relative;
              display: block;
              overflow: hidden;
              width: 44%;
              height: 60px;
              text-transform: uppercase;
              box-shadow: 0px 3px 6px #0000001a;
              background-image: linear-gradient(107deg,
                  #202a50 0%,
                  #0e77bd 50%,
                  #073c5f 100%);
              color: #e7f0f4;
              border-radius: 5px;
              font-size: 16px;
              line-height: 60px;
              text-align: center;
              font-weight: 600;
              font-family: "DIN_2014";
              transition: 0.9s ease-in-out;
              border: 0px solid;
              border-image-slice: 1;
              border-image-source: linear-gradient(107deg,
                  #202a50 0%,
                  #0e77bd 50%,
                  #073c5f 100%);

              &::before {
                content: "";
                position: absolute;
                top: 0;
                right: -50px;
                bottom: 0;
                left: 0;
                border-right: 50px solid transparent;
                border-bottom: 60px solid #e7f0f4;
                transform: translateX(-100%);
                transition: 0.6s ease-in-out;
              }

              &:hover {
                &::before {
                  transform: translateX(0);
                  z-index: -1;
                }

                color: #152c40;
                z-index: 9;
                border-width: 2px;

                .arrow-icon {
                  background-color: #152c40;
                }
              }
            }

            .arrow-icon {
              position: absolute;
              right: 15px;
              top: 25px;
              transition: 0.9s ease-in-out;
              background-color: #e7f0f4;
              background-repeat: no-repeat;
              display: inline-block;
              height: 11px;
              -webkit-mask-image: url(../images/arrow_icon.svg);
              mask-image: url(../images/arrow_icon.svg);
              width: 14px;
            }
          }
        }
      }
    }

    .col-image {
      width: 50%;
      height: 695px;

      img {
        width: 100%;
        height: 695px;
      }

      .WatSapp-icon {
        position: sticky;
        right: 30px;
        bottom: 0px;
        z-index: 999;

        .whatsapp-icon {
          background-color: #25d366;
          width: 100px;
          height: 100px;
          border-radius: 50px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 12px 25px rgb(0 0 0 / 42%);
          position: fixed;
          right: 15px;
          bottom: 15px;

          a {
            img {
              width: 50px;
              height: 50px;
            }
          }
        }
      }
    }
  }

  .home-new-section {
    // background-color: #212121;
    position: relative;

    .col-image {
      background-image: url(../../ulits/images/bg-image.png);
      width: 100%;
      overflow: hidden;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #212121;
      background-position: center;
    }

    .date-inner-title {
      padding: 100px 0 90px 0;

      .date-title {
        h1 {
          text-align: center;
          color: #313a41;
          font-size: 34px;
          line-height: 43px;
          font-weight: 600;
          font-family: "DIN_2014";
          margin-bottom: 65px;
        }
      }

      .box-wrapper {
        display: flex;
        justify-content: center;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        .box {
          background-color: #f3f4f4;
          width: 100%;
          height: auto;
          overflow: hidden;
          margin-bottom: 0px;

          img {
            width: 100%;
            height: 200px;
            object-fit: cover;
          }

          .box-content-inner {
            display: flex;
            justify-content: space-between;
            padding: 29px 14px;

            .box-content {
              width: 75%;

              h1 {
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                color: #313a41;
                font-family: "DIN_2014";
              }

              ul {
                padding: 0;
                margin: 0;
                list-style: none;
                display: flex;
                justify-content: flex-start;

                li {
                  font-size: 11px;
                  font-weight: 600;
                  line-height: 12px;
                  color: #313a41;
                  margin-right: 20px;
                  font-family: "DIN_2014";
                  letter-spacing: 0;

                  &:last-child {
                    margin-right: 0;
                  }

                  a {
                    color: #313a41;
                    text-decoration: none;

                    img {
                      width: 13px;
                      height: 13px;
                      object-fit: contain;
                      vertical-align: bottom;
                      margin-right: 5px;
                    }
                  }
                }
              }
            }

            button {
              &.primary-btn {
                background-color: #8c888d;
                border-radius: 5px;
                color: #e7f0f4;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                padding: 6px 14px;
                font-size: 12px;
                font-weight: 600;
                border: 0;
                height: 37px;
                font-family: "DIN_2014";
                letter-spacing: 1px;
              }
            }
          }
        }
      }

      button {
        &.primary-btn-list {
          position: relative;
          display: block;
          overflow: hidden;
          width: 14%;
          height: 50px;
          text-transform: uppercase;
          border: 2px solid #e7f0f3;
          box-shadow: 0px 3px 6px #0000001a;
          background-color: #313a41;
          color: #e7f0f4;
          border-radius: 5px;
          font-size: 15px;
          line-height: 23px;
          text-align: center;
          font-weight: 600;
          font-family: "DIN_2014";
          transition: 0.9s ease-in-out;
          margin: 46px auto 0;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            right: -50px;
            bottom: 0;
            left: 0;
            border-right: 50px solid transparent;
            border-bottom: 60px solid #e7f0f4;
            transform: translateX(-100%);
            transition: 0.6s ease-in-out;
          }

          &:hover {
            &::before {
              transform: translateX(0);
              z-index: -1;
            }

            color: #152c40;
            z-index: 9;
            border-color: #152c40;
          }
        }
      }
    }
  }

  .home-choose-us-section {
    background-color: #f3f4f4;
    padding: 90px 0 30px;

    .heading-title {
      h1 {
        font-family: "DIN_2014";
        font-size: 34px;
        line-height: 43px;
        text-align: center;
        color: #313a41;
        margin-bottom: 65px;
        font-weight: 600;
      }
    }

    .choose-us-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:nth-child(1) {
        .choose-us-box {
          &:nth-child(1) {
            .choose-us-content {
              background-color: #969084;
            }
          }

          &:nth-child(2) {
            .choose-us-content {
              background-color: #63737a;
            }
          }
        }
      }

      &:nth-child(2) {
        .choose-us-box {
          &:nth-child(1) {
            .choose-us-content {
              background-color: #99abb3;
            }
          }

          &:nth-child(2) {
            .choose-us-content {
              background-color: #ab8c79;
            }
          }
        }
      }

      .choose-us-box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 50px;
        margin-bottom: 50px;
        width: 100%;

        &:last-child {
          margin-right: 0;
        }

        .choose-us-content {
          background-color: #969084;
          padding: 40px 26px;
          text-align: center;
          width: 312px;
          height: 313px;

          img {
            width: 67px;
            object-fit: contain;
            height: 67px;
            filter: brightness(0) invert(1);
          }

          h2 {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            color: #fff;
            margin: 0;
            padding: 20px 0 20px 0px;
            position: relative;
            font-family: "DIN_2014";

            &::after {
              content: "";
              display: block;
              width: 50px;
              height: 2px;
              background-color: #fff;
              margin: 20px auto 0;
            }
          }

          p {
            font-size: 13px;
            font-weight: 400;
            line-height: 21px;
            color: #fff;
            margin-bottom: 0;
            font-family: "DIN_2014";
          }
        }

        .choose-us-icon {
          img {
            width: 100%;
            height: 313px;
          }
        }
      }
    }

    .choose-us-wrapper {
      &:nth-child(3) {
        .choose-us-box {
          margin-bottom: 0;
        }
      }
    }
  }

  .google-review-section {
    background-color: #e7f0f4;
    padding: 100px 0;

    .heading-title {
      h1 {
        font-family: "DIN_2014";
        font-size: 34px;
        line-height: 43px;
        text-align: center;
        color: #313a41;
        margin-bottom: 50px;
        font-weight: 900;
      }
    }

    .google-review-wrapper-box {
      .u-wrapper {
        padding-top: 30px;
        .swiper {
          .swiper-wrapper {
            transition-timing-function: linear;
            .swiper-slide {
              .google-review-box {
                background-color: #ffffff;
                border-radius: 5px;
                border: 1px solid #aab9c0;
                box-shadow: 0px 3px 6px #00000029;
                padding: 29px;
                margin-right: 26px;
        
                &:last-child {
                  margin-right: 0;
                }
        
                .google-review-img {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 20px;
        
                  img {
                    &.google-img {
                      width: 140px;
                    }
        
                    &.star-icon {
                      width: 27px;
                    }
                  }
                }
        
                .google-review-content {
                  h2 {
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 21px;
                    color: #152c40;
                    margin-bottom: 0;
                    font-family: "DIN_2014";
                  }
        
                  p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #152c40;
                    margin-bottom: 0;
                    padding: 20px 0;
                    font-family: "DIN_2014";
                  }
                }
              }
            }
          }
        }
      }
    }

    .google-review-wrapper-inner {
      background-color: #ffffff;
      border-radius: 5px;
      border: 1px solid #aab9c0;
      box-shadow: 0px 3px 6px #00000029;
      padding: 15px 20px;
      width: 415px;
      margin: 0 auto;

      .google-content-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          &.google-img {
            width: 27px;
          }

          &.star-icon {
            width: 108px;
          }
        }

        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 21px;
          color: #152c40;
          font-family: "DIN_2014";
          margin-bottom: 0;
        }
      }
    }

    .google-review-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 60px;
    }
  }

  .join-our-section {
    background-color: #fff;

    .join-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .join-title-inner {
        width: 50%;
        padding: 0 134px;

        h1 {
          font-size: 34px;
          font-weight: 600;
          line-height: 43px;
          color: #152c40;
          font-family: "DIN_2014";
          margin-bottom: 30px;
        }

        .join-form {
          position: relative;

          input {
            width: 100%;
            height: 55px;
            border-radius: 5px;
            border: 0px;
            padding: 0 20px;
            font-size: 16px;
            font-weight: 500;
            background-color: #f3f4f4;
            outline: none;
            font-family: "DIN_2014";
            color: #77868d;
          }

          .arrow-icons {
            position: absolute;
            right: 20px;
            top: 23px;
            transition: 0.9s ease-in-out;
            background-color: #152c40;
            background-repeat: no-repeat;
            display: inline-block;
            height: 11px;
            -webkit-mask-image: url(../images/arrow_icon.svg);
            mask-image: url(../images/arrow_icon.svg);
            width: 14px;
          }
        }
      }

      .join-img {
        width: 50%;

        img {
          width: 100%;
        }
      }
    }
  }

  .follow-us-section {
    // background-color: #161615;
    position: relative;

    .col-image {
      position: relative;

      img {
        width: 100%;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(101deg,
            #202a50 0%,
            #083858 50%,
            #073c5f 100%);
        opacity: 82%;
        left: 0;
      }
    }

    .col-content {
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
      right: 0;
      text-align: center;
      margin: 0 auto;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      h2 {
        font-size: 34px;
        font-weight: 600;
        line-height: 43px;
        color: #e7f0f4;
        font-family: "DIN_2014";
        margin-bottom: 30px;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        li {
          margin-right: 30px;

          &:last-child {
            margin-right: 0;
          }

          a {
            img {
              width: 90px;
            }
          }

          &:nth-child(1) {
            &:hover {
              a {
                img {
                  background: linear-gradient(45deg,
                      rgb(0, 255, 255),
                      rgb(255, 0, 128));
                  border-radius: 50px;
                }
              }
            }
          }

          &:nth-child(2) {
            &:hover {
              a {
                img {
                  background-color: yellow;
                  border-radius: 50px;
                }
              }
            }
          }

          &:nth-child(3) {
            &:hover {
              a {
                img {
                  background: linear-gradient(188deg,
                      rgb(131, 58, 180),
                      rgb(193, 53, 132),
                      rgb(225, 48, 108),
                      rgb(253, 29, 29),
                      rgb(245, 96, 64),
                      rgb(247, 119, 55),
                      rgb(252, 175, 69),
                      rgb(255, 220, 128),
                      rgb(188, 42, 141),
                      rgb(64, 93, 230));
                  border-radius: 50px;
                }
              }
            }
          }

          &:nth-child(4) {
            &:hover {
              a {
                img {
                  background: linear-gradient(45deg,
                      rgb(66, 103, 178),
                      rgb(37, 76, 140));
                  border-radius: 50px;
                }
              }
            }
          }
        }
      }
    }
  }

  .brands-stock {
    background-color: #fff;
    padding: 100px 0;

    .head-title {
      h2 {
        font-size: 34px;
        font-weight: 600;
        line-height: 43px;
        color: #152c40;
        font-family: "DIN_2014";
        margin-bottom: 65px;
      }

      .u-wrapper {
        padding-bottom: 45px;
        margin: 0 auto;
        width: 100%;

        &:last-child {
          padding-bottom: 0;
        }
      }

      .swiper {
        padding: 0 0px 50px 0px;

        &:last-child {
          padding: 0 0px 0px 0;
        }

        .swiper-wrapper {
          transition-timing-function: linear;

          .swiper-slide {
            background-color: #e7f0f4;
            border-radius: 50%;
            padding: 38px;

            // &.swiper-slide-active {
            //   background-image: linear-gradient(180deg,
            //       #202a50 0%,
            //       #0e77bd 50%,
            //       #073c5f 100%);

            //   img {
            //     filter: brightness(0) invert(1);
            //   }
            // }

            img {
              object-fit: contain;
            }
          }
        }
      }

      .swiper-container {
        width: 100%;
        height: 300px;
        margin: 0 auto;
      }

      .swiper-slide img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media Screen and (max-width: 1199px) {
  .main-wrapper {
    .home-col-section {
      flex-direction: column;

      .col-text.blue-bg {
        width: 100%;

        .our-courses-box {
          padding: 30px 76px;
          height: 314px;

          .date-inner-title {
            .date-title {
              width: 100%;
              left: 0;
              position: relative;
              height: 100%;
              align-items: center;

              h1 {
                font-size: 24px;

                line-height: 31px;
                margin-bottom: 15px;
              }

              p {
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                margin-bottom: 20px;
                -webkit-line-clamp: 2;
                width: 60%;
              }

              button.primary-btn {
                width: 100%;
              }
            }
          }
        }

        .swiper {

          .swiper-horizontal>.swiper-pagination-bullets,
          .swiper-pagination-bullets.swiper-pagination-horizontal {
            width: 100% !important;
            bottom: 15px !important;
          }
        }
      }

      .col-image {
        width: 100%;
      }
    }

    .home-new-section {
      .date-inner-title {
        padding: 45px 12px 45px 12px;

        .date-title {
          h1 {
            font-size: 24px;
            line-height: 31px;
            margin-bottom: 25px;
          }
        }

        .box-wrapper {
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
          padding: 0 0;

          &:nth-child(3) {
            display: none;
          }

          .box {
            width: 100%;

            .box-content-inner {
              padding: 22px 16px;

              .box-content {
                h1 {
                  font-size: 14px;
                }

                ul {
                  li {
                    a {
                      font-size: 11px;
                    }
                  }
                }
              }
            }
          }
        }

        button.primary-btn-list {
          width: 50%;
          margin: 60px auto 0;
          height: 60px;
        }
      }
    }

    .home-choose-us-section {
      padding: 35px 0;
      background-color: #f3f4f4;
      overflow: hidden;

      .heading-title {
        h1 {
          font-size: 24px;
          line-height: 31px;

          margin-bottom: 30px;
        }
      }

      .choose-us-box {
        display: flex;
        overflow: auto;
        margin: 0 -15px;
        padding-left: 15px;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .choose-us-wrapper {
        padding-right: 12px;

        &:last-child {
          padding-right: 0;
        }

        .choose-us-box {
          flex-direction: column-reverse;
          margin-right: 12px;
          margin-bottom: 20px;

          .choose-us-icon {
            width: 100%;

            img {
              height: 200px;
              object-fit: cover;
            }
          }
        }
      }
    }

    .google-review-section {
      padding: 44px 0;
      overflow: hidden;

      .heading-title {
        h1 {
          font-size: 24px;
          line-height: 31px;

          margin-bottom: 22px;
        }
      }

      .google-review-wrapper-inner {
        width: 100%;
      }

      .google-review-wrapper-box {
        overflow: auto;
        margin: 0 -13px;
        padding-left: 15px;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .google-review-wrapper {
        padding-top: 30px;
        justify-content: flex-start;
        width: 1031px;

        .google-review-box {
          margin-right: 12px;

          &:last-child {
            margin-right: 12px;
          }

          .google-review-img {
            margin-bottom: 26px;

            img {
              &.star-icon {
                width: 100px;
                display: none;
              }

              &.google-img {
                width: 200px;
              }
            }
          }
        }
      }
    }

    .join-our-section {
      .join-title {
        .join-title-inner {
          width: 50%;
          padding: 26px 12px 38px;

          h1 {
            font-size: 24px;
            line-height: 31px;

            margin-bottom: 20px;
            text-align: center;
          }
        }

        .join-img {
          width: 50%;
        }
      }
    }

    .follow-us-section {
      .col-image {
        img {
          height: 250px;
          object-fit: cover;
        }
      }

      .col-content {
        h2 {
          font-size: 24px;
          line-height: 31px;
        }

        ul {
          li {
            margin-right: 0px;

            &:nth-child(4) {
              display: none;
            }

            a {
              img {
                width: 76%;
              }
            }
          }
        }
      }
    }

    .brands-stock {
      padding: 40px 0;

      .head-title {
        h2 {
          font-size: 24px;
          line-height: 31px;

          margin-bottom: 20px;
        }

        .swiper {
          padding: 0 0px 30px 0px;

          &:last-child {
            padding: 0 0px 0px 0;
          }

          .swiper-wrapper {
            .swiper-slide {
              padding: 22px;
              width: 140px !important;
              height: 140px;

              img {
                object-fit: contain;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }
}

@media Screen and (max-width: 992px) {
  .main-wrapper {
    .home-col-section {
      flex-direction: column;

      .col-text.blue-bg {
        width: 100%;

        .our-courses-box {
          padding: 30px 12px;
          height: 287px;

          .date-inner-title {
            .date-title {
              width: 100%;
              left: 0;
              position: relative;
              height: 100%;
              align-items: center;

              h1 {
                font-size: 24px;
                font-family: "com-DIN_2014_Demi";
                line-height: 31px;
                margin-bottom: 15px;
              }

              p {
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                margin-bottom: 20px;
              }

              button.primary-btn {
                width: 100%;
              }
            }
          }
        }

        .swiper {

          .swiper-horizontal>.swiper-pagination-bullets,
          .swiper-pagination-bullets.swiper-pagination-horizontal {
            width: 100% !important;
            bottom: 15px !important;
          }
        }
      }

      .col-image {
        width: 100%;
      }
    }

    .home-new-section {
      .col-image {
        img {
          object-fit: cover;
          height: 2120px;
        }
      }

      .date-inner-title {
        padding: 45px 0px 45px 0px;

        .date-title {
          h1 {
            font-size: 24px;
            line-height: 31px;
            margin-bottom: 25px;
          }
        }

        .box-wrapper {
          flex-direction: column;
          padding: 0 0;
          grid-template-columns: repeat(1, 1fr);
          gap: 20px;

          &:nth-child(3) {
            display: none;
          }

          .box {
            width: 100%;

            .box-content-inner {
              padding: 22px 16px;

              .box-content {
                h1 {
                  font-size: 14px;
                }

                ul {
                  li {
                    a {
                      font-size: 11px;
                    }
                  }
                }
              }
            }
          }
        }

        button.primary-btn-list {
          width: 50%;
          margin: 60px auto 0;
          height: 60px;
        }
      }
    }

    .home-choose-us-section {
      padding: 35px 0;
      background-color: #f3f4f4;
      overflow: hidden;

      .heading-title {
        h1 {
          font-size: 24px;
          line-height: 31px;

          margin-bottom: 30px;
        }
      }

      .choose-us-box {
        display: flex;
        overflow: auto;
        margin: 0 -15px;
        padding-left: 15px;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .choose-us-wrapper {
        padding-right: 12px;

        &:last-child {
          padding-right: 0;
        }

        .choose-us-box {
          flex-direction: column-reverse;
          margin-right: 12px;
          margin-bottom: 20px;

          .choose-us-icon {
            width: 100%;

            img {
              height: 200px;
              object-fit: cover;
            }
          }
        }
      }
    }

    .google-review-section {
      padding: 44px 0;
      overflow: hidden;

      .heading-title {
        h1 {
          font-size: 24px;
          line-height: 31px;

          margin-bottom: 22px;
        }
      }

      .google-review-wrapper-inner {
        width: 100%;
      }

      .google-review-wrapper-box {
        overflow: auto;
        margin: 0 -13px;
        padding-left: 15px;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .google-review-wrapper {
        padding-top: 30px;
        justify-content: flex-start;
        width: 1031px;

        .google-review-box {
          margin-right: 12px;

          &:last-child {
            margin-right: 12px;
          }

          .google-review-img {
            margin-bottom: 26px;

            img {
              &.star-icon {
                width: 100px;
                display: none;
              }

              &.google-img {
                width: 200px;
              }
            }
          }
        }
      }
    }

    .join-our-section {
      .join-title {
        flex-direction: column-reverse;

        .join-title-inner {
          width: 100%;
          padding: 26px 12px 38px;

          h1 {
            font-size: 24px;
            line-height: 31px;

            margin-bottom: 20px;
            text-align: center;
          }
        }

        .join-img {
          width: 100%;
        }
      }
    }

    .follow-us-section {
      .col-image {
        img {
          height: 250px;
          object-fit: cover;
        }
      }

      .col-content {
        h2 {
          font-size: 24px;
          line-height: 31px;
        }

        ul {
          li {
            margin-right: 0px;

            &:nth-child(4) {
              display: none;
            }

            a {
              img {
                width: 76%;
              }
            }
          }
        }
      }
    }

    .brands-stock {
      padding: 40px 0;

      .head-title {
        h2 {
          font-size: 24px;
          line-height: 31px;

          margin-bottom: 20px;
        }

        .swiper {
          padding: 0 0px 30px 0px;

          &:last-child {
            padding: 0 0px 0px 0;
          }

          .swiper-wrapper {
            .swiper-slide {
              padding: 22px;
              width: 140px !important;
              height: 140px;

              img {
                object-fit: contain;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }
}

@media Screen and (max-width: 1366px) {
  .main-wrapper .join-our-section .join-title .join-title-inner {
    width: 50%;
    padding: 30px 115px;
  }

  .main-wrapper .home-col-section .col-text.blue-bg .our-courses-box .date-inner-title button.primary-btn {
    width: 56%;
  }
}

@media Screen and (max-width: 767px) {
  .main-wrapper {
    .home-col-section {
      flex-direction: column;

      .col-text.blue-bg {
        width: 100%;
        height: 100%;

        .our-courses-box {
          padding: 30px 12px;
          height: 256px;

          .date-inner-title {
            .date-title {
              width: 100%;
              left: 0;
              position: relative;
              height: 100%;
              align-items: center;

              h2 {
                font-size: 24px;
                font-family: "com-DIN_2014_Demi";
                line-height: 31px;
                margin-bottom: 15px;
                text-align: center;
              }

              p {
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                margin-bottom: 20px;
                width: 90%;
              }

              button.primary-btn {
                width: 100%;
              }
            }
          }
        }

        .swiper {

          .swiper-horizontal>.swiper-pagination-bullets,
          .swiper-pagination-bullets.swiper-pagination-horizontal {
            width: 100% !important;
            bottom: 15px !important;
          }

        }
      }

      .col-image {
        width: 100%;
        height: 100%;

        img {
          height: 100%;
        }

        .WatSapp-icon {
          .whatsapp-icon {
            bottom: 15px;
            width: 80px;
            height: 80px;
            right: 12px;

            a {
              img {
                width: 40px;
                height: 40px;
              }
            }
          }
        }
      }
    }

    .home-new-section {
      .col-image {
        img {
          object-fit: cover;
          height: 2120px;
        }
      }

      .date-inner-title {
        padding: 45px 0px 44px 0px;

        .date-title {
          h1 {
            font-size: 24px;
            line-height: 31px;
            margin-bottom: 25px;
          }
        }

        .box-wrapper {
          padding: 0 0;
          grid-template-columns: repeat(1, 1fr);
          gap: 15px;

          &:nth-child(3) {
            display: none;
          }

          .box {
            width: 100%;

            .box-content-inner {
              padding: 22px 7px;

              .box-content {
                width: 100%;

                h1 {
                  font-size: 14px;
                }

                ul {
                  li {
                    margin-right: 11px;
                    font-size: 0.7rem;

                    a {
                      font-size: 0.7rem;
                    }
                  }
                }
              }
            }
          }
        }

        button.primary-btn-list {
          width: 100%;
          margin: 30px auto 0;
          height: 60px;
        }
      }
    }

    .home-choose-us-section {
      padding: 35px 0;
      background-color: #f3f4f4;
      overflow: hidden;

      .heading-title {
        h1 {
          font-size: 24px;
          line-height: 31px;
          margin-bottom: 30px;
        }
      }

      .choose-us-box {
        display: flex;
        overflow: auto;
        margin: 0 -15px;
        padding-left: 15px;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .choose-us-wrapper {
        padding-right: 12px;

        &:last-child {
          padding-right: 0;
        }

        .choose-us-box {
          flex-direction: column-reverse;
          margin-right: 12px;
          margin-bottom: 20px;

          .choose-us-icon {
            width: 100%;

            img {
              height: 200px;
              object-fit: cover;
            }
          }
        }
      }
    }

    .google-review-section {
      padding: 44px 0;
      overflow: hidden;

      .heading-title {
        h1 {
          font-size: 24px;
          line-height: 31px;

          margin-bottom: 22px;
        }
      }

      .google-review-wrapper-inner {
        width: 100%;
      }

      .google-review-wrapper-box {
        overflow: auto;
        margin: 0 -13px;
        padding-left: 0px;

        &::-webkit-scrollbar {
          display: none;
        }
        .u-wrapper {
          .swiper {
            .swiper-wrapper {
              padding-left: 11px;
            }
          }
        }
      }

      .google-review-wrapper {
        padding-top: 30px;
        justify-content: flex-start;
        width: 1031px;

        .google-review-box {
          margin-right: 12px;

          &:last-child {
            margin-right: 12px;
          }

          .google-review-img {
            margin-bottom: 26px;

            img {
              &.star-icon {
                width: 100px;
                display: none;
              }

              &.google-img {
                width: 200px;
              }
            }
          }
        }
      }
    }

    .join-our-section {
      .join-title {
        flex-direction: column-reverse;

        .join-title-inner {
          width: 100%;
          padding: 26px 12px 38px;

          h1 {
            font-size: 24px;
            line-height: 31px;

            margin-bottom: 20px;
            text-align: center;
          }
        }
      }
    }

    .follow-us-section {
      .col-image {
        img {
          height: 250px;
          object-fit: cover;
        }
      }

      .col-content {
        h2 {
          font-size: 24px;
          line-height: 31px;
        }

        ul {
          li {
            margin-right: 0px;

            &:nth-child(4) {
              display: none;
            }

            a {
              img {
                width: 76%;
              }
            }
          }
        }
      }
    }

    .brands-stock {
      padding: 40px 0;

      .head-title {
        h2 {
          font-size: 24px;
          line-height: 31px;

          margin-bottom: 20px;
        }
        .u-wrapper {
          padding-bottom: 25px;
        }

        .swiper {
          padding: 0 0px 30px 13px;

          &:last-child {
            padding: 0 0px 0px 0;
          }

          .swiper-wrapper {
            transition-timing-function: linear;

            .swiper-slide {
              padding: 22px;
              width: 140px !important;
              height: 140px;

              img {
                object-fit: contain;
                margin: 0 auto;
              }
            }
          }
        }

        .slider-container {
          width: 100%;
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 0;
          }

          .slick-slider {
            .slick-arrow.slick-prev {
              display: none !important;
            }

            .slick-arrow.slick-next {
              display: none !important;
            }

            .slick-list {
              height: 140px !important;

              .slick-track {
                height: 150px !important;
                transition-timing-function: linear !important;

                .slick-slide {
                  padding: 22px;
                  width: 140px !important;
                  height: 140px;
                  background-color: #e7f0f4;
                  border-radius: 50%;
                  margin-right: 12px;

                  img {
                    object-fit: contain;
                    margin: 0 auto;
                  }
                }
              }
            }
          }
        }

        .slick-slide img {
          max-height: 150px;
          /* Adjust height for smaller screens */
        }
      }
    }
  }
}

@media Screen and (max-device-width: 430px) {
  .main-wrapper .home-new-section .date-inner-title .box-wrapper .box .box-content-inner .box-content ul li {
    margin-right: 10px;
    font-size: 0.7rem;
    width: 100%;

    a {
      font-size: 0.7rem;
      width: 100%;
    }

  }
}

@media Screen and (max-device-width: 390px) {
  .main-wrapper .home-new-section .date-inner-title .box-wrapper .box .box-content-inner .box-content ul li {
    margin-right: 10px;
    font-size: 0.7rem;

    a {
      font-size: 0.7rem;
    }
  }

  .main-wrapper .home-new-section .date-inner-title .box-wrapper .box .box-content-inner button.primary-btn {
    padding: 6px 10px;
  }
}

@media Screen and (max-device-width: 375px) {
  .main-wrapper .home-new-section .date-inner-title .box-wrapper .box .box-content-inner .box-content ul li {
    margin-right: 11px;

    a {
      font-size: 10px;
    }
  }
}