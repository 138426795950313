.main-wrapper {
  .finance-section {
    padding: 55px 0 0;

    .finance-img {
      img {
        width: 100%;
        padding-bottom: 40px;
      }
    }

    .finance-title {
      h1 {
        text-align: center;
        font-family: "DIN_2014";
        font-size: 34px;
        line-height: 43px;
        margin-bottom: 30px;
        font-weight: 600;
      }

      p {
        font-size: 18px;
        line-height: 30px;
        color: #77868d;
        font-family: "DIN_2014";
        margin-bottom: 40px;
        font-weight: 300;
      }
    }

    .finance-list {
      h1 {
        font-family: "DIN_2014";
        font-size: 32px;
        line-height: 43px;
        margin-bottom: 13px;
        font-weight: 600;
      }

      h3 {
        font-family: "DIN_2014";
        font-size: 21px;
        line-height: 27px;
        margin-bottom: 40px;
        font-weight: 600;
        padding-top: 20px;
      }

      .details-form {
        form {
          .form-group {
            margin-bottom: 20px;
            .form-control {
              border-radius: 5px;
              border: 1px solid #77868d;
              box-shadow: 0px 0px 3px #00000029;
              height: 50px;
              padding: 0 20px;
              font-size: 12px;
              line-height: 25px;
              color: #77868d;
              font-weight: 400;
              font-family: "DIN_2014";
              background-color: #fff;
              margin-bottom: 0px;

              &::placeholder {
                font-size: 12px;
                line-height: 25px;
                color: #77868d;
                font-weight: 400;
                font-family: "DIN_2014";
              }
            }
          }

          .dropdown-container {
            position: relative;
            width: 200px;
          }

          .dropdown-toggle {
            width: 100%;
            padding: 10px;
            background-color: #333;
            color: #fff;
            border: none;
            cursor: pointer;
            text-align: left;
          }

          .dropdown-toggle:focus {
            outline: none;
          }

          .dropdown-list {
            position: absolute;
            width: 100%;
            margin: 0;
            padding: 0;
            list-style: none;
            background-color: #444;
            border: 1px solid #555;
            z-index: 1000;
          }

          .dropdown-item {
            padding: 10px;
            cursor: pointer;
            color: #ccc;
          }

          .dropdown-item:hover {
            background-color: #555;
          }

          .active {
            background-color: #0066cc;
            color: #fff;
          }
        }
      }

      button {
        &.primary-btn {
          background-image: linear-gradient(107deg, #202A50 0%, #0E77BD 50%, #073C5F 100%);
          border: 0px solid #152c40;
          border: 0px solid; 
          border-image-slice: 0;
          border-image-source: linear-gradient(107deg, #202A50 0%, #0E77BD 50%, #073C5F 100%);
          border-radius: 5px;
          width: 40%;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          line-height: 23px;
          color: #ffffff;
          font-family: "DIN_2014";
          margin: 35px auto 75px;
          box-shadow: none;
          transition: 0.5s;
          font-weight: 600;

          &:hover {
            background-color: #f3f4f4;
            color: #f3f4f4;
            transition: 0.5s;
          }
        }
      }
    }

    .stm-accordion-single-unit {
      background-color: #f3f4f4;
      padding: 80px 0;

      .stm-accordion-title {
        h3 {
          font-family: "DIN_2014";
          font-size: 32px;
          line-height: 43px;
          margin-bottom: 50px;
          color: #152c40;
          text-align: center;
          font-weight: 600;
        }
      }

      .accordion {
        border: 0;
        --bs-accordion-bg: #ffffff00;

        .accordion-item {
          border-bottom: 1px solid #77868d;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          border-radius: 0;

          .accordion-header {
            .accordion-button {
              font-family: "DIN_2014";
              font-size: 18px;
              line-height: 32px;
              color: #152c40;
              background-color: transparent;
              box-shadow: none;
              padding: 20px 0;
              font-weight: 600;

              &.collapsed {
                &::after {
                  background-image: url(../images/plus_icon.svg);
                  width: 16px;
                  height: 16px;
                  background-size: 16px;
                }
              }

              &::after {
                background-image: url(../images/minus_icon.svg);
                height: 3px;
                width: 16px;
              }
            }
          }

          .accordion-collapse {
            .accordion-body {
              padding: 0 0px 25px;
              font-size: 14px;
              font-family: "Lato";
              line-height: 25px;
            }
          }
        }
      }
    }

    .wpb_wrapper {
      padding: 50px 0;

      ul {
        padding: 0;
        list-style: none;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: auto;

        &::-webkit-scrollbar {
          display: none;
        }

        li {
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          // &:nth-child(2) {
          //   img {
          //     width: 370px;
          //   }
          // }

          // &:nth-child(3) {
          //   img {
          //     width: 285px;
          //   }
          // }

          &:nth-child(4) {
            margin-right: 15px;
          }

          // &:nth-child(5) {
          //   img {
          //     width: 191px;
          //   }
          // }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .main-wrapper {
    .finance-section {
      padding: 27px 0 0;

      .title {
        h2 {
          font-size: 24px;
          line-height: 31px;
          margin-bottom: 20px;
          text-align: center;
        }
      }

      .finance-img {
        img {
          height: 220px;
          object-fit: cover;
          width: 100%;
          padding-bottom: 26px;
        }
      }

      .finance-title {
        h1 {
          font-size: 22px;
          text-align: left;
          line-height: 27px;
          margin-bottom: 19px;
        }

        p {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 17px;
        }
      }

      .finance-list {
        h1 {
          font-size: 22px;
          text-align: left;
          line-height: 27px;
          margin-bottom: 19px;
        }

        h3 {
          font-size: 16px;
          text-align: left;
          line-height: 20px;
          margin-bottom: 19px;
        }

        .details-form {
          form {
            .form-control {
              margin-bottom: 15px;
            }
          }
        }

        button {
          &.primary-btn {
            width: 100%;
            margin: 15px auto 50px;
          }
        }
      }

      .stm-accordion-single-unit {
        padding: 48px 0;

        .stm-accordion-title {
          h3 {
            font-size: 21px;
            line-height: 27px;
            margin-bottom: 30px;
            text-align: center;
          }
        }

        .accordion {
          .accordion-item {
            &:last-child {
              border-bottom: 0;
            }

            .accordion-header {
              .accordion-button {
                font-size: 14px;
              }
            }

            .accordion-collapse {
              .accordion-body {
                font-size: 12px;
                line-height: 21px;
              }
            }
          }
        }
      }

      .wpb_wrapper {
        padding: 46px 0;

        .container {
          padding: 0;
        }

        ul {
          padding: 0 0 0 12px;

          li {
            margin-right: 20px;

            &:last-child {
              margin-right: 0;
            }

            img {
              width: 228px;
            }

            &:nth-child(2) {
              img {
                width: 450px;
              }
            }

            &:nth-child(3) {
              img {
                width: 450px;
              }
            }

            &:nth-child(3) {
              img {
                width: 450px;
              }

              &:nth-child(5) {
                img {
                  width: 450px;
                }
              }
            }
          }
        }
      }
    }
  }
}