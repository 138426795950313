.containts-page-section {
    padding: 40px 0;
    h1 {
        font-size: 2.2rem;
        padding-bottom: 1.5rem;
        color: #152c40;
    }
    h2 {
        font-size: 1.5rem;
    }
    ul {
        padding: 0 0 0 18px;
    }
}