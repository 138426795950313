.site-header {
  position: sticky;
  top: 0;
  z-index: 1050;

  .header-top {
    background-color: #fff;
    padding: 14px 0;

    .header-top-left {
      img {
        margin: 0 auto;
        width: 25%;
      }
    }

    .header-top-center {
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        justify-content: space-evenly;

        li {
          a {
            color: #77868d;
            text-decoration: none;
            font-weight: 600;
            font-family: "DIN_2014";
            font-size: 1rem;
            position: relative;

            &::after {
              content: '';
              width: 0%;
              height: 2px;
              display: block;
              background-color: #152c40;
              position: absolute;
              top: 25px;
              left: 50%;
              transition: left 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            &:hover::after {
              width: 100%;
              left: 0;
            }

            &:hover {
              color: #313a41;
            }

            &.active {
              color: #313a41;

              &::after {
                width: 100%;
                left: 0;
              }
            }
          }
        }
      }
    }

    .header-top-right {
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        justify-content: end;

        li {
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }

          a {
            color: #77868d;
            text-decoration: none;

            img {
              // filter: brightness(0) invert(0);
              width: 90%;
              transition: transform 0.3s ease, color 0.3s ease;

              &:hover {
                animation: ring 1.2s ease-in-out 1;
              }
            }

            @keyframes ring {
              0% {
                transform: rotate(0);
              }

              10% {
                transform: rotate(-15deg);
              }

              20% {
                transform: rotate(15deg);
              }

              30% {
                transform: rotate(-15deg);
              }

              40% {
                transform: rotate(15deg);
              }

              50% {
                transform: rotate(0);
              }

              100% {
                transform: rotate(0);
              }
            }
          }
        }
      }
    }

    .sidebar {
      display: none;
    }
  }

  .header-bottom {
    background-image: linear-gradient(93deg, #101527 0%, #063250 50%, #072336 100%);

    .header-bottom-center {
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        justify-content: space-evenly;

        li {
          position: relative;
          display: inline-block;
          padding: 24px 81px;
          cursor: pointer;

          a {
            color: #fff;
            text-decoration: none;
            padding: 25px 0px;
            font-weight: 500;
            font-family: "DIN_2014";

            &:hover {
              color: #e7f0f4;
            }
          }

          &.dropdown {
            position: relative;
            display: inline-block;

            .dropdown-content {
              position: absolute;
              top: 100%;
              left: 0;
              background-color: #fff;
              min-width: 200px;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
              opacity: 0;
              visibility: hidden;
              transform: translateY(-10px);
              transition: opacity 0.6s ease, transform 0.6s ease;
              z-index: 1;

              ul {
                flex-direction: column;

                li {
                  padding: 6px 39px;
                  text-align: center;

                  &:hover {
                    background-image: linear-gradient(93deg, #202A50 0%, #0E77BD 50%, #073C5F 100%);

                    a {
                      color: #e7f0f4;
                    }
                  }

                  a {
                    display: block;
                    padding: 10px 20px;
                    color: #152c40;
                    text-decoration: none;
                  }
                }
              }
            }

            &:hover {
              .dropdown-content {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: opacity 0.6s ease, transform 0.6s ease;
              }
            }
          }

          &.active {
            a {
              color: #e7f0f4;
            }
          }
        }
      }
    }
  }
}

@media Screen and (max-width: 992px) {
  .site-header {
    .header-top {
      box-shadow: 0px 3px 3px #0000000d;
      padding: 18px 0;

      .header-top-left {
        text-align: left;

        img {
          width: 50%;
        }
      }

      .header-top-center {
        display: none;
      }

      .header-top-right {
        display: none;
      }

      .sidebar {
        display: block;
        text-align: end;

        .menu-button {
          outline: 0;
          border: 0;
          background: transparent;
          padding-right: 0;
          background-image: url(../images/menu_icon.svg);
          width: 20px;
          height: 17px;
        }

        .menu {
          width: 100%;
          background-image: linear-gradient(156deg, #202A50 0%, #0E77BD 50%, #073C5F 100%);
          position: fixed;
          top: 0px;
          right: -100%;
          margin: 0;
          text-align: left;
          z-index: 99;
          height: 100vh;
          transition: 0.8s !important;

          .hide-desk {
            padding: 30px 25px;

            .meno-menu {
              .mobo-menu {
                text-align: left;
                padding: 0px;
                margin: 22px 0 0;
                list-style: none;

                li {
                  margin: 0px;
                  border-bottom: 1px solid #e0e0e075;
                  padding: 25px 0;

                  a {
                    color: #f3f4f4;
                    text-decoration: none;
                    font-size: 16px;
                    line-height: 23px;
                    font-weight: 600;
                    font-family: "DIN_2014";
                    letter-spacing: 1px;
                  }

                  .dropdown {
                    position: relative;
                    top: 0px;
                    margin: 0 auto;
                    width: 100%;
                    background: transparent;

                    .dropdown__text {
                      color: #ffffff;
                      padding: 0px 0px;
                      cursor: pointer;
                      height: auto;
                      text-decoration: none;
                      font-size: 16px;
                      line-height: 23px;
                      font-weight: 600;
                      font-family: "DIN_2014";
                      letter-spacing: 1px;

                      &::after {
                        content: "";
                        transition: all 0.3s;
                        border: solid #f3f4f4;
                        border-width: 0 2px 2px 0;
                        float: right;
                        margin-top: 5px;
                        margin-right: 2px;
                        padding: 4px;
                        transform: rotate(-135deg);
                        -webkit-transform: rotate(45deg);
                      }
                    }

                    .dropdown__items {
                      visibility: hidden;
                      opacity: 0;
                      max-height: 0;
                      margin-top: 0;
                      transition: max-height 0.7s ease, opacity 0.5s ease,
                        visibility 0.5s ease;

                      .dropdown__item {
                        cursor: pointer;
                        padding: 10px 18px;
                        color: #f3f4f4;
                      }
                    }

                    &.active {
                      .dropdown__items {
                        visibility: visible;
                        opacity: 1;
                        max-height: 290px;
                        margin-top: 12px;
                        transition: max-height 0.7s ease, opacity 0.5s ease,
                          visibility 0.3s ease;

                        .dropdown__item {
                          cursor: pointer;
                          padding: 7px 0px;

                          a {
                            color: #fff;
                            padding: 0px 0;
                            text-decoration: none;
                            font-size: 16px;
                            line-height: 23px;
                            font-weight: 400;
                            font-family: "DIN_2014";
                            letter-spacing: 1px;
                          }
                        }
                      }

                      .dropdown__text {
                        &::after {
                          margin-top: 9px;
                          transform: rotate(-135deg);
                          -webkit-transform: rotate(-135deg);
                        }
                      }
                    }
                  }
                }
              }

              .social-icon {
                .list-unstyled {
                  display: flex;
                  padding: 0px;
                  margin-bottom: 0;
                  list-style: none;

                  li {
                    margin: 0 33px 0 0;

                    &:last-child {
                      margin: 0 0 0 0;
                    }

                    a {
                      img {
                        width: 100%;
                        filter: brightness(0) invert(1);
                      }
                    }
                  }
                }
              }
            }
          }

          .sd-header {
            .menu-close {
              text-align: end;

              img {
                margin: 36px 24px 0 0px;
              }
            }
          }
        }

        .menu.open {
          right: 0;
          transition: all 0.7s;
        }
      }
    }

    .header-bottom {
      display: none;
    }
  }
}

@media Screen and (max-width: 767px) {
  .site-header {
    .header-top {
      box-shadow: 0px 3px 3px #0000000d;
      padding: 18px 0;

      .header-top-left {
        text-align: center;
        padding-left: 39px;

        img {
          width: 50%;
        }
      }

      .header-top-center {
        display: none;
      }

      .header-top-right {
        display: none;
      }

      .sidebar {
        display: block;
        text-align: end;

        .menu-button {
          outline: 0;
          border: 0;
          background: transparent;
          padding-right: 0;
          background-image: url(../images/menu_icon.svg);
          width: 20px;
          height: 17px;
          padding: 0;
        }

        .menu {
          width: 100%;
          background-image: linear-gradient(156deg, #202A50 0%, #0E77BD 50%, #073C5F 100%);
          position: fixed;
          top: 0px;
          right: -100%;
          margin: 0;
          text-align: left;
          z-index: 99;
          height: 100vh;
          transition: 0.8s !important;

          .hide-desk {
            padding: 30px 25px;

            .meno-menu {
              .mobo-menu {
                text-align: left;
                padding: 0px;
                margin: 22px 0 0;
                list-style: none;

                li {
                  margin: 0px;
                  border-bottom: 1px solid #e0e0e075;
                  padding: 25px 0;

                  a {
                    color: #f3f4f4;
                    text-decoration: none;
                    font-size: 16px;
                    line-height: 23px;
                    font-weight: 600;
                    font-family: "DIN_2014";
                    letter-spacing: 1px;
                  }

                  .dropdown {
                    position: relative;
                    top: 0px;
                    margin: 0 auto;
                    width: 100%;
                    background: transparent;

                    .dropdown__text {
                      color: #ffffff;
                      padding: 0px 0px;
                      font-weight: 600;
                      font-family: "DIN_2014";
                      font-size: 16px;
                      line-height: 23px;
                      cursor: pointer;
                      height: auto;

                      &::after {
                        content: "";
                        transition: all 0.3s;
                        border: solid #f3f4f4;
                        border-width: 0 2px 2px 0;
                        float: right;
                        margin-top: 5px;
                        margin-right: 2px;
                        padding: 4px;
                        transform: rotate(-135deg);
                        -webkit-transform: rotate(45deg);
                      }
                    }

                    .dropdown__items {
                      visibility: hidden;
                      opacity: 0;
                      max-height: 0;
                      margin-top: 0;
                      transition: max-height 0.7s ease, opacity 0.5s ease,
                        visibility 0.5s ease;

                      .dropdown__item {
                        cursor: pointer;
                        padding: 10px 18px;
                      }
                    }

                    &.active {
                      .dropdown__items {
                        visibility: visible;
                        opacity: 1;
                        max-height: 290px;
                        margin-top: 12px;
                        transition: max-height 0.7s ease, opacity 0.5s ease,
                          visibility 0.3s ease;

                        .dropdown__item {
                          cursor: pointer;
                          padding: 7px 0px;

                          a {
                            color: #fff;
                            padding: 0px 0;
                            font-weight: 500;
                            font-family: "DIN_2014";
                            font-size: 16px;
                          }
                        }
                      }

                      .dropdown__text {
                        &::after {
                          margin-top: 9px;
                          transform: rotate(-135deg);
                          -webkit-transform: rotate(-135deg);
                        }
                      }
                    }
                  }
                }
              }

              .social-icon {
                .list-unstyled {
                  display: flex;
                  padding: 0px;
                  margin-bottom: 0;
                  list-style: none;

                  li {
                    margin: 0 33px 0 0;

                    &:last-child {
                      margin: 0 0 0 0;
                    }

                    a {
                      img {
                        width: 100%;
                        filter: brightness(0) invert(1);
                      }
                    }
                  }
                }
              }
            }
          }

          .sd-header {
            .menu-close {
              text-align: end;

              img {
                margin: 36px 24px 0 0px;
              }
            }
          }
        }

        .menu.open {
          right: 0;
          transition: all 0.7s;
        }
      }
    }

    .header-bottom {
      display: none;
    }
  }
}

@media Screen and (max-device-width: 555px) {
  .site-header {
    .header-top {
      box-shadow: 0px 3px 3px #0000000d;
      padding: 18px 0;

      .header-top-left {
        text-align: center;
        padding-left: 39px;

        img {
          width: 41% !important;
        }
      }

      .header-top-center {
        display: none;
      }

      .header-top-right {
        display: none;
      }

      .sidebar {
        display: block;
        text-align: end;

        .menu-button {
          outline: 0;
          border: 0;
          background: transparent;
          padding-right: 0;
          background-image: url(../images/menu_icon.svg);
          width: 20px;
          height: 17px;
          padding: 0;
        }

        .menu {
          width: 100%;
          background-color: #152c40;
          position: fixed;
          top: 0px;
          right: -100%;
          margin: 0;
          text-align: left;
          z-index: 99;
          height: 100vh;
          transition: 0.8s !important;

          .hide-desk {
            padding: 30px 25px;

            .meno-menu {
              .mobo-menu {
                text-align: left;
                padding: 0px;
                margin: 22px 0 0;
                list-style: none;

                li {
                  margin: 0px;
                  border-bottom: 1px solid #e0e0e075;
                  padding: 25px 0;

                  a {
                    color: #f3f4f4;
                    text-decoration: none;
                  }

                  .dropdown {
                    position: relative;
                    top: 0px;
                    margin: 0 auto;
                    width: 100%;
                    background: transparent;

                    .dropdown__text {
                      color: #ffffff;
                      padding: 0px 0px;
                      cursor: pointer;
                      height: auto;

                      &::after {
                        content: "";
                        transition: all 0.3s;
                        border: solid #f3f4f4;
                        border-width: 0 2px 2px 0;
                        float: right;
                        margin-top: 5px;
                        margin-right: 2px;
                        padding: 4px;
                        transform: rotate(-135deg);
                        -webkit-transform: rotate(45deg);
                      }
                    }

                    .dropdown__items {
                      visibility: hidden;
                      opacity: 0;
                      max-height: 0;
                      margin-top: 0;
                      transition: max-height 0.7s ease, opacity 0.5s ease,
                        visibility 0.5s ease;

                      .dropdown__item {
                        cursor: pointer;
                        padding: 10px 18px;
                      }
                    }

                    &.active {
                      .dropdown__items {
                        visibility: visible;
                        opacity: 1;
                        max-height: 290px;
                        margin-top: 12px;
                        transition: max-height 0.7s ease, opacity 0.5s ease,
                          visibility 0.3s ease;

                        .dropdown__item {
                          cursor: pointer;
                          padding: 7px 0px;

                          a {
                            color: #fff;
                            padding: 0px 0;
                          }
                        }
                      }

                      .dropdown__text {
                        &::after {
                          margin-top: 9px;
                          transform: rotate(-135deg);
                          -webkit-transform: rotate(-135deg);
                        }
                      }
                    }
                  }
                }
              }

              .social-icon {
                .list-unstyled {
                  display: flex;
                  padding: 0px;
                  margin-bottom: 0;
                  list-style: none;

                  li {
                    margin: 0 33px 0 0;

                    &:last-child {
                      margin: 0 0 0 0;
                    }

                    a {
                      img {
                        width: 100%;
                        filter: brightness(0) invert(1);
                      }
                    }
                  }
                }
              }
            }
          }

          .sd-header {
            .menu-close {
              text-align: end;

              img {
                margin: 36px 24px 0 0px;
              }
            }
          }
        }

        .menu.open {
          right: 0;
          transition: all 0.7s;
        }
      }
    }

    .header-bottom {
      display: none;
    }
  }
}

@media Screen and (max-device-width: 480px) {
  .site-header {
    .header-top {
      box-shadow: 0px 3px 3px #0000000d;
      padding: 18px 0;

      .header-top-left {
        text-align: center;
        padding-left: 39px;

        img {
          width: 49% !important;
        }
      }

      .header-top-center {
        display: none;
      }

      .header-top-right {
        display: none;
      }

      .sidebar {
        display: block;
        text-align: end;

        .menu-button {
          outline: 0;
          border: 0;
          background: transparent;
          padding-right: 0;
          background-image: url(../images/menu_icon.svg);
          width: 20px;
          height: 17px;
          padding: 0;
        }

        .menu {
          width: 100%;
          background-color: #152c40;
          position: fixed;
          top: 0px;
          right: -100%;
          margin: 0;
          text-align: left;
          z-index: 99;
          height: 100vh;
          transition: 0.8s !important;

          .hide-desk {
            padding: 30px 25px;

            .meno-menu {
              .mobo-menu {
                text-align: left;
                padding: 0px;
                margin: 22px 0 0;
                list-style: none;

                li {
                  margin: 0px;
                  border-bottom: 1px solid #e0e0e075;
                  padding: 25px 0;

                  a {
                    color: #f3f4f4;
                    text-decoration: none;

                  }

                  .dropdown {
                    position: relative;
                    top: 0px;
                    margin: 0 auto;
                    width: 100%;
                    background: transparent;

                    .dropdown__text {
                      color: #ffffff;
                      padding: 0px 0px;
                      cursor: pointer;
                      height: auto;

                      &::after {
                        content: "";
                        transition: all 0.3s;
                        border: solid #f3f4f4;
                        border-width: 0 2px 2px 0;
                        float: right;
                        margin-top: 5px;
                        margin-right: 2px;
                        padding: 4px;
                        transform: rotate(-135deg);
                        -webkit-transform: rotate(45deg);
                      }
                    }

                    .dropdown__items {
                      visibility: hidden;
                      opacity: 0;
                      max-height: 0;
                      margin-top: 0;
                      transition: max-height 0.7s ease, opacity 0.5s ease,
                        visibility 0.5s ease;

                      .dropdown__item {
                        cursor: pointer;
                        padding: 10px 18px;
                      }
                    }

                    &.active {
                      .dropdown__items {
                        visibility: visible;
                        opacity: 1;
                        max-height: 290px;
                        margin-top: 12px;
                        transition: max-height 0.7s ease, opacity 0.5s ease,
                          visibility 0.3s ease;

                        .dropdown__item {
                          cursor: pointer;
                          padding: 7px 0px;

                          a {
                            color: #fff;
                            padding: 0px 0;
                          }
                        }
                      }

                      .dropdown__text {
                        &::after {
                          margin-top: 9px;
                          transform: rotate(-135deg);
                          -webkit-transform: rotate(-135deg);
                        }
                      }
                    }
                  }
                }
              }

              .social-icon {
                .list-unstyled {
                  display: flex;
                  padding: 0px;
                  margin-bottom: 0;
                  list-style: none;

                  li {
                    margin: 0 33px 0 0;

                    &:last-child {
                      margin: 0 0 0 0;
                    }

                    a {
                      img {
                        width: 100%;
                        filter: brightness(0) invert(1);
                      }
                    }
                  }
                }
              }
            }
          }

          .sd-header {
            .menu-close {
              text-align: end;

              img {
                margin: 36px 24px 0 0px;
              }
            }
          }
        }

        .menu.open {
          right: 0;
          transition: all 0.7s;
        }
      }
    }

    .header-bottom {
      display: none;
    }
  }
}

@media Screen and (max-device-width: 390px) {
  .site-header {
    .header-top {
      box-shadow: 0px 3px 3px #0000000d;
      padding: 18px 0;

      .header-top-left {
        text-align: center;
        padding-left: 39px;

        img {
          width: 49% !important;
        }
      }

      .header-top-center {
        display: none;
      }

      .header-top-right {
        display: none;
      }

      .sidebar {
        display: block;
        text-align: end;

        .menu-button {
          outline: 0;
          border: 0;
          background: transparent;
          padding-right: 0;
          background-image: url(../images/menu_icon.svg);
          width: 20px;
          height: 16.5px;
          padding: 0;
        }

        .menu {
          width: 100%;
          background-color: #152c40;
          position: fixed;
          top: 0px;
          right: -100%;
          margin: 0;
          text-align: left;
          z-index: 99;
          height: 100vh;
          transition: 0.8s !important;

          .hide-desk {
            padding: 30px 25px;

            .meno-menu {
              .mobo-menu {
                text-align: left;
                padding: 0px;
                margin: 22px 0 0;
                list-style: none;

                li {
                  margin: 0px;
                  border-bottom: 1px solid #e0e0e075;
                  padding: 25px 0;

                  a {
                    color: #f3f4f4;
                    text-decoration: none;
                  }

                  .dropdown {
                    position: relative;
                    top: 0px;
                    margin: 0 auto;
                    width: 100%;
                    background: transparent;

                    .dropdown__text {
                      color: #ffffff;
                      padding: 0px 0px;

                      cursor: pointer;
                      height: auto;

                      &::after {
                        content: "";
                        transition: all 0.3s;
                        border: solid #f3f4f4;
                        border-width: 0 2px 2px 0;
                        float: right;
                        margin-top: 5px;
                        margin-right: 2px;
                        padding: 4px;
                        transform: rotate(-135deg);
                        -webkit-transform: rotate(45deg);
                      }
                    }

                    .dropdown__items {
                      visibility: hidden;
                      opacity: 0;
                      max-height: 0;
                      margin-top: 0;
                      transition: max-height 0.7s ease, opacity 0.5s ease,
                        visibility 0.5s ease;

                      .dropdown__item {
                        cursor: pointer;
                        padding: 10px 18px;
                      }
                    }

                    &.active {
                      .dropdown__items {
                        visibility: visible;
                        opacity: 1;
                        max-height: 290px;
                        margin-top: 12px;
                        transition: max-height 0.7s ease, opacity 0.5s ease,
                          visibility 0.3s ease;

                        .dropdown__item {
                          cursor: pointer;
                          padding: 7px 0px;

                          a {
                            color: #fff;
                            padding: 0px 0;

                          }
                        }
                      }

                      .dropdown__text {
                        &::after {
                          margin-top: 9px;
                          transform: rotate(-135deg);
                          -webkit-transform: rotate(-135deg);
                        }
                      }
                    }
                  }
                }
              }

              .social-icon {
                .list-unstyled {
                  display: flex;
                  padding: 0px;
                  margin-bottom: 0;
                  list-style: none;

                  li {
                    margin: 0 33px 0 0;

                    &:last-child {
                      margin: 0 0 0 0;
                    }

                    a {
                      img {
                        width: 100%;
                        filter: brightness(0) invert(1);
                      }
                    }
                  }
                }
              }
            }
          }

          .sd-header {
            .menu-close {
              text-align: end;

              img {
                margin: 36px 24px 0 0px;
              }
            }
          }
        }

        .menu.open {
          right: 0;
          transition: all 0.7s;
        }
      }
    }

    .header-bottom {
      display: none;
    }
  }
}

@media Screen and (max-device-width: 360px) {
  .site-header {
    .header-top {
      box-shadow: 0px 3px 3px #0000000d;
      padding: 18px 0;

      .header-top-left {
        text-align: center;
        padding-left: 39px;

        img {
          width: 80%;
        }
      }

      .header-top-center {
        display: none;
      }

      .header-top-right {
        display: none;
      }

      .sidebar {
        display: block;
        text-align: end;

        .menu-button {
          outline: 0;
          border: 0;
          background: transparent;
          padding-right: 0;
          background-image: url(../images/menu_icon.svg);
          width: 50%;
          height: 17px;
          padding: 0;
        }

        .menu {
          width: 100%;
          background-color: #152c40;
          position: fixed;
          top: 0px;
          right: -100%;
          margin: 0;
          text-align: left;
          z-index: 99;
          height: 100vh;
          transition: 0.8s !important;

          .hide-desk {
            padding: 30px 25px;

            .meno-menu {
              .mobo-menu {
                text-align: left;
                padding: 0px;
                margin: 22px 0 0;
                list-style: none;

                li {
                  margin: 0px;
                  border-bottom: 1px solid #e0e0e075;
                  padding: 25px 0;

                  a {
                    color: #f3f4f4;
                    text-decoration: none;


                  }

                  .dropdown {
                    position: relative;
                    top: 0px;
                    margin: 0 auto;
                    width: 100%;
                    background: transparent;

                    .dropdown__text {
                      color: #ffffff;
                      padding: 0px 0px;

                      cursor: pointer;
                      height: auto;

                      &::after {
                        content: "";
                        transition: all 0.3s;
                        border: solid #f3f4f4;
                        border-width: 0 2px 2px 0;
                        float: right;
                        margin-top: 5px;
                        margin-right: 2px;
                        padding: 4px;
                        transform: rotate(-135deg);
                        -webkit-transform: rotate(45deg);
                      }
                    }

                    .dropdown__items {
                      visibility: hidden;
                      opacity: 0;
                      max-height: 0;
                      margin-top: 0;
                      transition: max-height 0.7s ease, opacity 0.5s ease,
                        visibility 0.5s ease;

                      .dropdown__item {
                        cursor: pointer;
                        padding: 10px 18px;
                      }
                    }

                    &.active {
                      .dropdown__items {
                        visibility: visible;
                        opacity: 1;
                        max-height: 290px;
                        margin-top: 12px;
                        transition: max-height 0.7s ease, opacity 0.5s ease,
                          visibility 0.3s ease;

                        .dropdown__item {
                          cursor: pointer;
                          padding: 7px 0px;

                          a {
                            color: #fff;
                            padding: 0px 0;

                          }
                        }
                      }

                      .dropdown__text {
                        &::after {
                          margin-top: 9px;
                          transform: rotate(-135deg);
                          -webkit-transform: rotate(-135deg);
                        }
                      }
                    }
                  }
                }
              }

              .social-icon {
                .list-unstyled {
                  display: flex;
                  padding: 0px;
                  margin-bottom: 0;
                  list-style: none;

                  li {
                    margin: 0 33px 0 0;

                    &:last-child {
                      margin: 0 0 0 0;
                    }

                    a {
                      img {
                        width: 100%;
                        filter: brightness(0) invert(1);
                      }
                    }
                  }
                }
              }
            }
          }

          .sd-header {
            .menu-close {
              text-align: end;

              img {
                margin: 36px 24px 0 0px;
              }
            }
          }
        }

        .menu.open {
          right: 0;
          transition: all 0.7s;
        }
      }
    }

    .header-bottom {
      display: none;
    }
  }
}