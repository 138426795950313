@import "https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css";
@import "https://fonts.googleapis.com/css2?family=Lato:wght@100;400;700;900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Cormorant:wght@700&display=swap";
@import "https://unpkg.com//style/react-tabs.css";

@font-face {
  font-family: "com-DIN_2014_Demi";
  src: url(../fonts/fonnts.com-DIN_2014_Demi.otf);
}

@font-face {
  font-family: "quincy-cf";
  src: url(../fonts/Fontspring-DEMO-quincycf-medium.otf);
}

@font-face {
  font-family: "DIN_2014";
  src: url(../fonts/fonnts.com-DIN_2014.otf);
}

@import "header.scss";
@import "footer.scss";
@import "home.scss";
@import "about.scss";
@import "contact.scss";
@import "blog-listing.scss";
@import "car-listing.scss";
@import "finance.scss";
@import "insurance.scss";
@import "privacy.scss";



.custom-loader {
  position: fixed;
  background-color: #fff;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  z-index: 9999;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  display: flex;
  // justify-content: center;
  align-items: center;

  .loader {
    position: fixed;
    margin: 0 auto;
    transform: translate(49%, 48%);
    width: 100%;
    height: 100%;

    &::before {
      content: "";
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: radial-gradient(farthest-side, #cccaca 94%, #0000) top/4px 4px no-repeat,
        conic-gradient(#0000 30%, #cccaca);
      -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
      animation: spinner-ub 0.8s infinite linear;
      position: absolute;
    }
  }


  .skeleton-container {
    width: 43%;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  /* Skeleton profile section */
  .skeleton-profile {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
  }

  .skeleton-circle {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: #e0e0e0;
    position: relative;
    overflow: hidden;
  }

  .skeleton-info {
    flex-grow: 1;
  }

  .skeleton-line {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    background: #e0e0e0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
  }

  .skeleton-line.short {
    width: 60%;
  }

  /* Skeleton content section */
  .skeleton-content .skeleton-line {
    margin-bottom: 15px;
  }

  /* Shimmer animation */
  .skeleton-circle::before,
  .skeleton-line::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 200%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    animation: shimmer 1.5s infinite;
  }

  @keyframes shimmer {
    0% {
      left: -100%;
    }

    100% {
      left: 100%;
    }
  }

  @keyframes spinner-ub {
    100% {
      transform: rotate(1turn);
    }
  }

}